import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Fade from "@material-ui/core/Fade";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MuiAlert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
import * as Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet, appStoreRemove, appStoreSet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";

import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";

const env = runtimeEnv();

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

const icon_modal = "/assets/images/icon_modal.png";
function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
  ...CommonStyles(theme),
  button_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "0",
    paddingRight: "0",
  },
  primary_button: {
    marginTop: "10px",
    background: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    // width: "60%",
  },
  primary_button_100: {
    marginTop: "10px",
    background: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
  },
  outlined_button: {
    marginTop: "10px",
    color: env.REACT_APP_PRIMARY_COLOR,
    borderColor: "#64003226",
    // width: "60%",
  },
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  page_container: {
    backgroundColor: "#ffffff !important",
    minHeight: "var(--doc-height)",
    paddingBottom: "100px",
    paddingTop: "10px",
    "& .MuiSvgIcon-root": {
      color: env.REACT_APP_PRIMARY_COLOR,
    },
    "& .MuiFormControlLabel-root": {
      "& .MuiTypography-body1": {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "22px",
        /* identical to box height, or 157% */
        letterSpacing: "0.0041em",
        color: "#000000",
      },
    },
    "& .Mui-disabled": {
      "& .MuiSvgIcon-root": {
        color: "#adb5bd",
      },
      "& .MuiTypography-body1": {
        color: "#adb5bd !important",
      },
    },
  },
  non_selected_card: {
    marginBottom: "20px",
    border: "none",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    "& .MuiCardContent-root": {
      padding: "12px 30px",
    },
    "& .MuiCardActions-root": {
      padding: "0px 17px 12px 17px",
    },
  },
  selected_card: {
    marginBottom: "20px",
    borderWidth: "3px",
    borderColor: env.REACT_APP_PRIMARY_COLOR,
    "& .MuiCardContent-root": {
      padding: "12px 30px",
    },
  },
  card_details: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
    "&:last-child": {
      marginBottom: "0px",
    },
    "& .MuiTypography-body1": {
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "22px",
      /* identical to box height, or 157% */
      letterSpacing: "0.0041em",
      color: "#000000",
    },
    "& .MuiTypography-body2": {
      fontSize: "14px",
      fontWeight: "400",
    },
  },
  payment_mode: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    marginLeft: theme.spacing(1),
    border: "none",
    flex: 1,
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
  },
  title_input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "18px",
    color: "white",
  },
  text_fields: {
    marginBottom: "30px",
  },
  fields_toolbar: {
    borderColor: "#C9C9C9",
    border: "1px solid",
    borderRadius: "4px",
    paddingLeft: "8px",
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  saveforlater: {
    marginTop: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#974e72 !important",
    cursor: "pointer",
  },
  btn_cancel: {
    marginTop: "24px",
    padding: "8px 5px",
    width: "100px",
    color: "#ffffff",
    backgroundColor: env.REACT_APP_PRIMARY_COLOR_HOVER,
    float: "left",
    fontSize: "14px",
    textTransform: "capitalize",
    cursor: "pointer",
    boxShadow: "none !important",
    "& .MuiTypography-body1": {
      color: "#ffffff !important",
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h2": {
      fontSize: "16px",
      color: "#2C2222",
      fontWeight: "600",
      textAlign: "center",
      padding: "20px 0px",
    },
    "& p": {
      fontSize: "12px",
      color: "#524A4A",
      textAlign: "center",
      padding: "5px 0px",
    },
    "& .MuiAvatar-root": {
      marginTop: "32px",
      width: "48px",
      height: "48px",
      margin: "auto",
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none !important",
    boxShadow: "none !important",
    padding: "10px 30px",
    borderRadius: "10px",
    paddingBottom: "32px",
    width: "20%",
    minWidth: '200px',
    maxWidth: '250px'
  },
  button_group: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button_section: {
    marginTop: "52px",
    display: "flex",
    justifyContent: "center",
  },
  btn_use_this_card: {
    backgroundColor: env.REACT_APP_PRIMARY_COLOR,
    textTransform: "capitalize",
    color: "white",
    boxShadow: "none !important",
    padding: "8px 5px",
    width: "159px",
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: "600",
      textTransform: "none !important",
    },
    "&:hover": {
      backgroundColor: env.REACT_APP_PRIMARY_COLOR_HOVER,
    },
  },
  btn_delete_card: {
    color: env.REACT_APP_PRIMARY_COLOR,
    padding: "8px 5px",
    width: "99px",
    border: "2px solid " + env.REACT_APP_PRIMARY_COLOR,
    borderRadius: "5px",
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: "600",
      textTransform: "none !important",
    },
    "&:hover": {
      color: "#ffffff",
      backgroundColor: env.REACT_APP_PRIMARY_COLOR,
      border: "none",
    },
  },
  btn_clear_cart: {
    padding: "8px 0px",
    color: env.REACT_APP_PRIMARY_COLOR,
    border: "2px solid " + env.REACT_APP_PRIMARY_COLOR,
    minWidth: "100px",
    float: "left",
    marginRight: "23px",
    textTransform: "capitalize",
    cursor: "pointer",
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: "600",
    },
    "&:hover": {
      color: "#ffffff",
      backgroundColor: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  btn_checkout: {
    padding: "8px 0px",
    minWidth: "96px",
    backgroundColor: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    marginLeft: "23px",
    textTransform: "capitalize",
    cursor: "pointer",
    boxShadow: "none !important",
    "& .MuiTypography-body1": {
      fontSize: "16px",
      fontWeight: "600",
    },
    "&:hover": {
      backgroundColor: env.REACT_APP_PRIMARY_COLOR_HOVER,
    },
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
  },
  icon: {
    color: env.REACT_APP_PRIMARY_COLOR,
    marginRight: "2px",
  },
  map_dimensions: {
    width: "100%",
    height: "400px",
  },
  dialog_title: {
    width: "100%",
    color: env.REACT_APP_PRIMARY_COLOR,
    fontSize: "17px",
  },
  dialog_header: {
    width: "100%",
    color: env.REACT_APP_PRIMARY_COLOR,
    fontSize: "22px",
    fontWeight: 1000,
  },
  dialog_content: {
    color: "#000000",
  },
  order_now_btn: {
    marginTop: "5%",
  },
  warning_text: {
    padding: "5%",
    fontSize: "12px",
  },
  no_decoration: {
    textDecoration: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackbar: {
    top: "48px !important",
    "& .MuiAlert-root": {
      padding: "0px !important",
    },
    "& .MuiAlert-outlinedError": {
      borderLeft: "none !important",
    },
    "& .MuiAlert-outlinedSuccess": {
      border: "1px solid #3BB719",
    },
    "& .MuiAlert-outlinedWarning": {
      border: "1px solid #ff9800",
    },
    "& .MuiAlert-outlinedWarning .MuiAlert-icon": {
      backgroundColor: "#ff9800 !important",
      color: "#fff !important",
      padding: "20px 20px !important",
    },
    "& .MuiAlert-outlinedError .MuiAlert-icon": {
      backgroundColor: "#f44336 !important",
      color: "#fff !important",
      padding: "20px 20px !important",
    },
    "& .MuiAlert-outlinedSuccess .MuiAlert-icon": {
      backgroundColor: "#3BB719 !important",
      color: "#fff !important",
      padding: "20px 20px !important",
    },
    "& .MuiAlert-message": {
      padding: "22px 0px",
      backgroundColor: "#fff !important",
    },
    "& .MuiAlert-action": {
      marginRight: "10px !important",
      paddingLeft: "0px !important",
    },
  },
});

function InitiatePayment(props) {
  const history = useHistory();
  const { classes } = props;
  const dispatch = useDispatch();

  let created_order = null;
  if (appStoreGet("credit_card_order") !== null)
    created_order = JSON.parse(appStoreGet("credit_card_order"));

  const [credit_cards, setCreditCards] = useState([]);
  const [selected_credit_card, setSelectCreditCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initial_loading, setInitialLoading] = useState(true);
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);
  const [new_card_checked, checkNewCard] = React.useState(false);
  const [setStatus] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openDeletCardModal, setOpenDeleteCardModal] = useState(false);
  const [loans, setLoans] = useState([]);
  const [loans_ids, setLoansIds] = useState([])
  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const retailer = retailer_data;
  const corporate_settings = retailer.corporate_settings

  const getCorporateSetting = (key, property) => {
    return corporate_settings && corporate_settings[key] && corporate_settings[key][property];
  }

  const loan = useSelector((state) => state.orders.order.loan);

  useEffect(() => {
    fetchCreditCards();
  }, []);

  useEffect(() => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login')
    }

    setLoading(true);
    fetch(env.REACT_APP_SERVER_API_URL + "/loans/loans_awaiting_repayment", {
      headers: { ...authorization_headers(), ...{ Authorization: token } },
    })
      .then((res) => res.text())
      .then((res) => {
        let data = JSON.parse(decrypt(res));
        let ids = data.map((loan) => loan.id);
        setLoans(data);
        setLoansIds(ids)
        setLoading(false);
      })
      .catch(() => {
      });
  }, []);

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
      currencyDisplay: "narrowSymbol",
    });

    const comma_seperated = formatter
      .format(number)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return comma_seperated.replace(/\s/g, "");
  };

  const orderTotal = () => {
    let total = 0;

    if (appStoreGet("credit_card_mode") === "ordering") {
      created_order.line_items.forEach(function (item) {
        total += item.quantity * parseFloat(item.price);
      });
    } else {
      total = appStoreGet("repayment_amount");
    }

    return total;
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const fetchCreditCards = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push("/login");
    }

    setInitialLoading(true);
    fetch(process.env.REACT_APP_SERVER_API_URL + "/retailers/credit_cards", {
      method: "GET",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
    })
      .then((res) => res.text())
      .then((res) => {
        let data = JSON.parse(decrypt(res));
        setInitialLoading(false);
        setCreditCards(data);
      })
      .catch(() => {
        setInitialLoading(false);
        setSnackbarSeverity("error");
        setSnackbarMessage("Error fetching credit cards.");
        openSnackbar(true);
      });
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const fetchingCreditCards = (classes) => {
    if (initial_loading) {
      return (
        <Container className={classes.card_top_padding}>
          <Skeleton variant="rect" height={50} animation="wave" />
          <Skeleton variant="text" animation="wave" />
          <Skeleton variant="text" width={"50%"} animation="wave" />
        </Container>
      );
    }
  };

  const selectCreditCard = (credit_card) => {
    setSelectCreditCard(credit_card);
    checkNewCard(false);
  };

  const deleteCreditCard = (credit_card) => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push("/login");
    }

    setLoading(true);
    fetch(
      process.env.REACT_APP_SERVER_API_URL +
        "/retailers/credit_cards/" +
        credit_card.id,
      {
        method: "DELETE",
        headers: { ...authorization_headers(), ...{ Authorization: token } },
      }
    ).then((res) => {
      res
        .text()
        .then((text) => {
          if (res.ok) {
            fetchCreditCards();
            setSnackbarMessage("Card successfully deleted.");
            setSnackbarSeverity("success");
            openSnackbar(true);
          } else {
            let data = JSON.parse(decrypt(text));
            setSnackbarSeverity("warning");
            setSnackbarMessage(data.error);
            openSnackbar(true);
          }

          setLoading(false);
        })
        .catch((err) => {
          setSnackbarSeverity("warning");
          setSnackbarMessage("Error deleting the card.");
          openSnackbar(true);
          setLoading(false);
        });
    });
  };

  const displayCreditCards = (classes) => {
    if (initial_loading) {
      return;
    }

    if (credit_cards.length > 0) {
      return (
        <>
          {credit_cards.map((card, index) => (
            <>
              <Card
                className={
                  selected_credit_card && selected_credit_card.id == card.id
                    ? classes.selected_card
                    : classes.non_selected_card
                }
                key={index}
                variant="outlined"
              >
                <CardContent>
                  <Box className={classes.card_details}>
                    <Typography variant="h6">Card number:</Typography>
                    <Typography variant="h6">{card.number}</Typography>
                  </Box>
                  <Box className={classes.card_details}>
                    <Typography variant="h6">Card type:</Typography>
                    <Typography variant="h6">{card.type_name}</Typography>
                  </Box>
                  <Box className={classes.card_details}>
                    <Typography variant="h6">Card expiry date:</Typography>
                    <Typography variant="h6">
                      {card.card_expiry_date}
                    </Typography>
                  </Box>
                  <Box className={classes.card_details}>
                    <Typography variant="h6">Added on:</Typography>
                    <Typography variant="h6">{card.date_added}</Typography>
                  </Box>
                </CardContent>
                <CardActions className={classes.button_group}>
                  <Button
                    variant="contained"
                    className={classes.btn_use_this_card}
                    onClick={() => selectCreditCard(card)}
                  >
                    <Typography>Pay with this card</Typography>
                  </Button>

                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.btn_delete_card}
                    onClick={handleOpenDeleteCardModal}
                  >
                    <Typography>Delete</Typography>
                  </Button>
                </CardActions>
              </Card>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openDeletCardModal}
                onClose={handleCloseDeletCardModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={openDeletCardModal}>
                  <div className={classes.paper}>
                    <Avatar
                      src={icon_modal}
                      variant="square"
                      className={classes.icon_modal}
                    />
                    <Typography variant="h2">Delete card?</Typography>

                    <Container className={classes.button_container}>
                      <Button
                        variant="outlined"
                        align="center"
                        size="large"
                        fullWidth={true}
                        type="submit"
                        className={classes.outlined_button}
                        onClick={handleCloseDeletCardModal}
                        style={{ marginRight: "10px" }}
                      >
                        <b>Cancel</b>
                      </Button>

                      <Button
                        variant="contained"
                        align="center"
                        size="large"
                        fullWidth={true}
                        type="submit"
                        className={classes.primary_button}
                        onClick={() => deleteCreditCard(card)}
                      >
                        <b>Delete</b>
                      </Button>
                    </Container>
                  </div>
                </Fade>
              </Modal>
            </>
          ))}
        </>
      );
    } else {
      return (
        <Container>
          <Typography align="center" variant="h6">
            No stored cards
          </Typography>

          <br />
          <Container className={classes.card_top_padding}>
            <Skeleton variant="rect" height={50} animation={false} />
            <Skeleton variant="text" animation={false} />
            <Skeleton variant="text" width={"50%"} animation={false} />
          </Container>
        </Container>
      );
    }
  };

  const useNewCardChange = (event) => {
    let checked = event.target.checked;
    checkNewCard(checked);
    setSelectCreditCard(null);
  };

  const makePayment = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push("/login");
    }

    let credit_card_id = null;

    if (selected_credit_card != null && !new_card_checked) {
      credit_card_id = selected_credit_card.id;
      appStoreSet("selected_credit_card", JSON.stringify(selected_credit_card));
    }

    setLoading(true);

    if (appStoreGet("credit_card_mode") === "ordering") {
      let req_data = JSON.stringify({
        order_id: created_order.id,
        credit_card_id: credit_card_id,
      });

      fetch(
        process.env.REACT_APP_SERVER_API_URL +
          "/credit_card/make_payment_with_credit_card",
        {
          method: "POST",
          headers: { ...authorization_headers(), ...{ Authorization: token } },
          body: encrypt(req_data),
        }
      ).then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              const data = JSON.parse(decrypt(text));

              appStoreSet(
                "credit_card_payment_details",
                JSON.stringify(data.cyber_source)
              );
              if (selected_credit_card != null) {
                appStoreSet(
                  "credit_card",
                  JSON.stringify(selected_credit_card)
                );
              } else {
                appStoreRemove("credit_card");
              }
              history.push("/confirm_payment");
            } else {
              let data = JSON.parse(decrypt(text));
              setSnackbarSeverity("warning");
              setSnackbarMessage(data.error);
              openSnackbar(true);
            }

            setLoading(false);
          })
          .catch((err) => {
            setSnackbarSeverity("warning");
            setSnackbarMessage("Error making a payment.");
            openSnackbar(true);
            setLoading(false);
          });
      });
    } else if (appStoreGet("credit_card_mode") === "repayment") {
      let req_data = JSON.stringify({
        loan_ids: getCorporateSetting(retailer.distributor_loan_days, "allow_multiple_loans") === true ? loans_ids : [loan.id],
        credit_card_id: credit_card_id,
        repayment_amount: appStoreGet("repayment_amount"),
      });

      fetch(
        process.env.REACT_APP_SERVER_API_URL +
          "/credit_card/make_loan_repayment",
        {
          method: "POST",
          headers: { ...authorization_headers(), ...{ Authorization: token } },
          body: encrypt(req_data),
        }
      ).then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              const data = JSON.parse(decrypt(text));

              appStoreSet(
                "credit_card_payment_details",
                JSON.stringify(data.cyber_source)
              );
              if (selected_credit_card != null) {
                appStoreSet(
                  "credit_card",
                  JSON.stringify(selected_credit_card)
                );
              } else {
                appStoreRemove("credit_card");
              }
              history.push("/confirm_payment");
            } else {
              let data = JSON.parse(decrypt(text));
              setSnackbarSeverity("warning");
              setSnackbarMessage(data.error);
              openSnackbar(true);
            }

            setLoading(false);
          })
          .catch((err) => {
            setSnackbarSeverity("warning");
            setSnackbarMessage("Error making a payment.");
            openSnackbar(true);
            setLoading(false);
          });
      });
    }
  };

  const handleOpenModal = () => {
    // setOpenModal(true);
    history.push("/");
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenDeleteCardModal = () => {
    setOpenDeleteCardModal(true);
  };

  const handleCloseDeletCardModal = () => {
    setOpenDeleteCardModal(false);
  };

  const backToOrderingPage = () => {
    history.push("/");
  };

  const orderID = () => {
    const data = {
      order_id: created_order.id,
    };

    return encrypt(JSON.stringify(data));
  };

  const cancelOrder = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push("/login");
    }

    setLoading(true);
    fetch(process.env.REACT_APP_SERVER_API_URL + "/orders/cancel_order", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: orderID(),
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              setLoading(false);
              history.push("/");
            } else {
              setStatus("failed");
            }
          })
          .catch(() => {
            setStatus("failed");
          });
      })
      .catch(() => {
        setStatus("failed");
      });
  };

  return (
    <ThemeProvider theme={appTheme}>
      <Paper
        className={classes.container}
        style={{
          height: "100vh",
        }}
      >
        <HeaderBar>
          <Toolbar>
            <Link to="/" className={classes.no_decoration}>
              <IconButton
                className={classes.iconButton}
                aria-label="directions"
                color="primary"
                disableRipple
              >
                <ArrowBackIosIcon />
              </IconButton>
            </Link>
            <Typography className={classes.title_input} align="center">
              <b>Card Selection</b>
            </Typography>
            <Link to="/" className={classes.no_decoration}>
              <Avatar
                src={env.REACT_APP_TRANSPARENT_LOGO}
                variant="square"
                className={classes.square}
              />
            </Link>
          </Toolbar>
        </HeaderBar>

        <Container className={classes.page_container}>
          {fetchingCreditCards(classes)}

          {displayCreditCards(classes)}

          <Box className={classes.card_details}>
            <Typography variant="h6">
              {appStoreGet("credit_card_mode") === "ordering"
                ? "Order total:"
                : "Repayment amount:"}
            </Typography>
            <Typography variant="h6">{toCurrency(orderTotal())}</Typography>
          </Box>
          <Box className={classes.payment_mode}>
            <FormControlLabel
              style={{ marginTop: "-10px" }}
              value="end"
              control={
                <Checkbox
                  name="use_a_new_card"
                  checked={new_card_checked}
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  onChange={useNewCardChange}
                />
              }
              label={<Typography variant="h6">Use a new card</Typography>}
              labelPlacement="end"
            />
          </Box>

          <Grid
            container
            alignContent="center"
            justifycontent="center"
            className={classes.button_section}
          >
            <Button
              variant="outlined"
              className={classes.btn_clear_cart}
              onClick={handleOpenModal}
            >
              <Typography>Cancel</Typography>
            </Button>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openModal}
              onClose={handleCloseModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openModal}>
                <div className={classes.paper}>
                  <Avatar
                    src={icon_modal}
                    variant="square"
                    className={classes.icon_modal}
                  />
                  <Typography variant="h2">Cancel order?</Typography>
                  <Typography>
                    You will need to add the items to your <br />
                    cart again.{" "}
                  </Typography>

                  <Typography
                    className={classes.saveforlater}
                    onClick={backToOrderingPage}
                  >
                    Save for later{" "}
                  </Typography>
                  <Grid container alignContent="center" justifyContent="center">
                    <Button
                      variant="contained"
                      className={classes.btn_cancel}
                      onClick={() => cancelOrder()}
                    >
                      <Typography>Yes, cancel it</Typography>
                    </Button>
                  </Grid>
                </div>
              </Fade>
            </Modal>

            <Button
              variant="contained"
              className={classes.btn_checkout}
              onClick={() => makePayment()}
            >
              <Typography>Next</Typography>
            </Button>
          </Grid>
        </Container>

        {snackbarLoader()}
        {progressLoader()}
      </Paper>
    </ThemeProvider>
  );
}

export default withStyles(styles)(InitiatePayment);
