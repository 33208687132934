import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import { grey } from '@material-ui/core/colors';
import Container from "@material-ui/core/Container";
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React from "react";
import { Link } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { appStoreGet } from "../utils/app_storage";

const env = runtimeEnv();

const primary_main_color = env.REACT_APP_PRIMARY_COLOR || '#640032'
const transparent_logo_path = env.REACT_APP_TRANSPARENT_LOGO_PATH || '/assets/images/logo_transparent.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: env.REACT_APP_PRIMARY_COLOR,
        },
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
    },
});

const styles = theme => ({
    ...CommonStyles(theme),
    confirm_payment_container: {
        padding: '100px 40px',
        // backgroundColor: '#ffffff !important',
        minHeight: 'var(--doc-height)',
        '& .MuiListItem-gutters': {
            borderBottom: 'none !important',
            paddingLeft: '0px !important',
        }
    },
    card_details: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '10px',
        '&:last-child': {
            marginBottom: '0px',
        },
        '& .MuiTypography-body1': {
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '22px',
            /* identical to box height, or 157% */
            letterSpacing: '0.0041em',
            color: '#000000',
        },
        '& .MuiTypography-body2': {
            fontSize: '14px',
            fontWeight: '400',
        },
    },
    iconButton: {
        padding: 10,
        color: 'white',
    },
    alert_override: {
        "&": {
            borderLeftWidth: '4px',
            borderTop: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            width: '100%',
            backgroundColor: 'white'
        },
        '& .MuiAlert-message': {
            color: '#5B4C4B'
        }
    },
    card_top_padding: {
        marginTop: '10px',
    },
    orders_items: {
        borderBottom: '1px solid',
        borderBottomColor: grey[400],
    },
    input: {
        marginLeft: theme.spacing(1),
        border: 'none',
        flex: 1,
        '& .MuiInputBase-input::placeholder': {
            fontStyle: 'italic'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none'
            },
        }
    },
    title_input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: '18px',
        color: 'white'
    },
    btn_checkout: {
        width: '169px',
        padding: '10px',
        position: 'absolute',
        bottom: '82px',
        left: '0',
        right: '0',
        margin: 'auto',
        background: env.REACT_APP_PRIMARY_COLOR,
        color: 'white',
        boxShadow: 'none !important',
        '&:hover': {
            background: env.REACT_APP_PRIMARY_COLOR_HOVER,
        },
        '& .MuiTypography-body1': {
            fontSize: '16px',
            fontWeight: '600',
            textTransform: 'none !important',
        },

    },

    dialog_content: {
        color: "#000000",
    },
    order_now_btn: {
        marginTop: '5%',
    },
    warning_text: {
        padding: '5%',
        fontSize: '12px',
    },
    no_decoration: {
        textDecoration: 'none'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        // color: '#fff',
        '& h4': {
            fontSize: '24px',
            marginLeft: '10px'
        }
    },
    snackbar: {
        textAlign: 'center',
        marginTop: '50px'
    },
});

function ConfirmPayment(props) {
    const {classes} = props
    const payment_data = JSON.parse(appStoreGet('credit_card_payment_details'))

    const data = payment_data.data.card_payment_data
    const credit_card = appStoreGet('selected_credit_card') && JSON.parse(appStoreGet('selected_credit_card'))
    
    const toCurrency = (number) => {
        const formatter = new Intl.NumberFormat('en-us', {
                style: 'currency',
                currency: 'KSH',
                currencyDisplay: 'narrowSymbol',
            }
        )

        const comma_seperated = formatter.format(number).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return comma_seperated.replace(/\s/g, "");
    }

    const paymentData = (key, data) => {


        data["currency"] = 'R'

        if ((key == "amount" || key == "reference_number" || key == "currency") &&
            !key.endsWith("name")) {
            let label = key.replace("bill_to_", "")
            let camel_cased_label;

            if (key == "reference_number") {
                camel_cased_label = key.replace("reference_number", "Reference");
            } else if (key == "amount") {
                camel_cased_label = key.replace("amount", "Order total");
            } else {
                camel_cased_label = label.split('_').map(function (elem) {
                    return elem.slice(0, 1).toUpperCase() + elem.slice(1)
                }).join('')
            }


            return (
                <ListItem className={classes.orders_items}>
                    <ListItemText
                        primary={
                            <Typography variant="body2">
                                <b>{camel_cased_label}: </b> <span align="right">
                                    {key == "amount" ? toCurrency(data[key]) : data[key]}</span>
                            </Typography>
                        }
                    />
                </ListItem>
            )
        }
    }

    const originatorDetails = () => {
        if (credit_card != null && credit_card != "") {
            return (
                <Box style={{ marginTop: "10px" }}>
                    <Box className={classes.card_details}>
                        <Typography variant="h6">
                            Card number:
                        </Typography>
                        <Typography variant="h6" >
                            {credit_card.number}
                        </Typography>
                    </Box>
                    <Box className={classes.card_details}>
                        <Typography variant="h6">
                            Card type:
                        </Typography>
                        <Typography variant="h6" >
                            {credit_card.type_name}
                        </Typography>
                    </Box>

                    <Box className={classes.card_details}>
                        <Typography variant="h6">
                            Card expiry date:
                        </Typography>
                        <Typography variant="h6" >
                            {credit_card.card_expiry_date}
                        </Typography>
                    </Box>

                    <Box className={classes.card_details}>
                        <Typography variant="h6">
                            Added on:
                        </Typography>
                        <Typography variant="h6" >
                        {credit_card.date_added}
                        </Typography>
                    </Box>
                </Box>
            )
        } else {
            return (
                <Box Box style={{ marginTop: "10px" }}>
                    <Box className={classes.card_details}>
                        <Typography variant="h6">
                        Retailer name:
                        </Typography>
                        <Typography variant="h6" >
                        {data.bill_to_forename + ' ' + data.bill_to_surname}
                        </Typography>
                    </Box>
                </Box>
            )
        }
    }

    return (
        <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
                <Toolbar>
                    <Link to='/' className={classes.no_decoration}>
                        <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                    disableRipple>
                            <ArrowBackIosIcon/>
                        </IconButton>
                    </Link>
                    <Typography className={classes.title_input} align="center">
                        <b>Card Selection</b>
                    </Typography>
                    <Link to='/' className={classes.no_decoration}>
                        <Avatar src={env.REACT_APP_TRANSPARENT_LOGO} variant="square" className={classes.square}/>
                    </Link>
                </Toolbar>
            </HeaderBar>

            <form id="payment_confirmation" action={env.REACT_APP_CYBERSOURCE_URL}
                  method="post">
                {Object.keys(data).map((key, index) => (
                    <input type="hidden" id={key} name={key} value={data[key]}/>
                ))}

                <Container className={classes.confirm_payment_container}>
                    <Box>
                        <Box className={classes.card_details}>
                            <Typography variant="h6">
                                Reference:
                            </Typography>
                            <Typography variant="h6" >
                                {data['reference_number']}
                            </Typography>
                        </Box>
                        <Box className={classes.card_details}>
                            <Typography variant="h6">
                            {appStoreGet('credit_card_mode') === 'ordering' ? 'Order total:' : 'Repayment amount:'}
                            </Typography>
                            <Typography variant="h6" >
                                { toCurrency(data['amount'])}
                            </Typography>
                        </Box>
                    </Box>
                    {originatorDetails()}

                    <Button type="submit" variant="contained"
                            size="large"
                            className={classes.btn_checkout}
                    >
                        <Typography>Confirm payment</Typography>
                    </Button>
                </Container>
            </form>
            </Paper>
        </ThemeProvider>
    );
}

export default withStyles(styles)(ConfirmPayment);
