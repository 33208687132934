import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Box, Button, Grid, Paper, Slider } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { amber, grey, red } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { set_loan } from "../store/modules/loans/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";



const env = runtimeEnv();
const transparent_logo_path =
  env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = env.REACT_APP_PRIMARY_COLOR || "#640032";

const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

const FinanceLimitSlider = withStyles({
  root: {
    color: red[900],
  },
})(Slider);

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
    secondary: {
      main: "#882456",
    },
    white: {
      main: grey[50],
      body1: {
        fontSize: "18px",
      },
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const payment_successful = "/assets/images/payment_successful.png";
const payment_failed = "/assets/images/payment_failed.png";

const styles = (theme) => ({
  ...CommonStyles(theme),
  fields_toolbar: {
    borderColor: "#737373",
    border: "2px solid",
    borderRadius: "5px",
    paddingLeft: "8px",
  },
  upload_container: {
    marginTop: "10px",
  },
  upload_fields_toolbar: {
    borderColor: "#737373",
    border: "2px solid",
    borderRadius: "5px",
    padding: "8px",
  },
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  customBadge: {
    backgroundColor: "#dc8a07",
    color: "white",
    width: "80px",
    fontWeight: "700",
  },
  outOfStockCustomBadge: {
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    color: "white",
    width: "105px",
    fontWeight: "700",
  },
  toolbar: {
    border: "1px solid",
    borderColor: env.REACT_APP_AGIZA_APP === "true" ? grey[500] : amber[700],
  },
  group_btns: {
    padding: "10px",
  },
  group_btn: {
    borderRadius: "20px",
    margin: 5,
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "unset",
  },
  group_buttons: {
    "MuiFab-sizeSmall": {
      height: "unset",
    },
  },
  primary_button: {
    marginTop: "10px",
    background: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
  },
  button_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "0",
    paddingRight: "0",
  },
  //   outlined_button: {
  //     marginTop: "10px",
  //     color: env.REACT_APP_PRIMARY_COLOR,
  //     border: "none",
  //     paddingLeft: "0",
  //     marginLeft: "4px",
  //   },
  outlined_button: {
    marginTop: "10px",
    color: env.REACT_APP_PRIMARY_COLOR,
    borderColor: "#64003226",
    // width: "60%",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "18px",
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  cat_extended_btn: {
    width: "110px",
    height: "30px",
  },
  btn_bottom: {
    border: "0px",
    borderColor: primary_main_color,
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
  },
  out_of_stock_btn: {
    height: "34px",
    width: "118px",
    minWidth: "118px",
    fontSize: "12px",
    borderRadius: "17px",
  },
  product_image: {
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  list_primary_text: {
    width: "180px",
  },
  list_primary_text_for_out_of_stock: {
    width: "180px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  no_decoration: {
    textDecoration: "none",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
  payment_successful_icon: {
    // position: 'absolute',
    // top: '254px',
    left: "0px",
    right: "0px",
    width: "90px",
    height: "90px",
    margin: "auto",
  },
  payment_successful_content: {
    // position: 'absolute',
    // top: '340px',
    left: "0px",
    right: "0px",
    width: "320px",
    margin: "auto",
  },
  payment_status_title: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "400",
    paddingTop: "15px",
  },

  payment_status_sub_title: {
    textAlign: "center",
    fontSize: "18px",
    color: "#565656",
    paddingTop: "32px",
    "& a": {
      color: primary_main_color,
      textDecoration: "none",
    },
  },

  payment_status_text: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: "12px",
    color: "#565656",
    paddingTop: "2px",
  },

  payment_status_text_failed: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: "12px",
    color: "#565656",
    paddingTop: "32px",
    "& span a": {
      color: primary_main_color,
      textDecoration: "none",
    },
  },
});

function LoanStatus(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const order = JSON.parse(appStoreGet("created_order"));
  
  const [loan_details, setLoanDetails] = useState(null);

  let order_data = JSON.stringify({
    order_id: order.id
  });


  useEffect(() => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login');
    }

  fetch(env.REACT_APP_SERVER_API_URL + "/orders/order_details", {
    method: "POST",
    headers: { ...authorization_headers(), ...{ Authorization: token } },
    body: encrypt(order_data),
  })
    .then((res) => res.text())
    .then((res) => {
      let data = JSON.parse(decrypt(res));
      setLoanDetails(data);
      console.log(data)
    })
    .catch((e) => {
      console.log('error')
      console.error(e)
    });
}, []);

  const loanDetails = (loan) => {
    dispatch(set_loan(loan));
    history.push("/loan_details");
  };

  const goHome = () => {
    window.location.reload(false);
    history.push("/");
  };

  const goToLoanHistory = () => {
    window.location.reload(false);
    history.push("/loan_history");
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography
                style={{ color: "white" }}
                className={classes.input}
                align="center"
              >
                Financing Status
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>

          <br />

          <Container fullWidth>
            <Grid
              container
              direction="column"
              alignContent="center"
              justifyContent="center"
              marginTop="50px"
              marginBottom="50px"
            >
              <Avatar
                src={payment_successful}
                variant="square"
                className={classes.payment_successful_icon}
              />
              <Box className={classes.payment_successful_content}>
                <Typography
                  variant="h5"
                  className={classes.payment_status_title}
                >
                  Successful
                </Typography>
                <Typography
                  variant="h5"
                  className={classes.payment_status_sub_title}
                >
                  Thank you!
                  <br />
                  <br />
                  Your financing request is being processed.
                </Typography>
              </Box>
            </Grid>

            <Button
              variant="contained"
              align="center"
              size="large"
              fullWidth={true}
              type="submit"
              className={classes.primary_button}
              onClick={() => goHome()}
            >
              <b>Finish</b>
            </Button>
            <Button
              variant="outlined"
              align="center"
              size="large"
              fullWidth={true}
              type="submit"
              className={classes.outlined_button}
              style={{ marginRight: "10px" }}
              onClick={() => goToLoanHistory()}
            >
              <b>View all loans</b>
            </Button>
          </Container>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}
export default withStyles(styles)(LoanStatus);
