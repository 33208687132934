const CryEnc = require('./crypto_encryption')

function appStoreSet(key, value) {
    try {
        let str_value = value.toString()
        let encrypted_key = CryEnc.encrypt(key)
        let encrypted_value = CryEnc.encrypt(str_value)
        localStorage.setItem(encrypted_key, encrypted_value)
    } catch (error) {
        if (process.env.NODE_ENV === "development")
            console.error(error)
        return null
    }
}

function appStoreGet(key) {
    try {
        let encrypted_key = CryEnc.encrypt(key)
        let encrypted_value = localStorage.getItem(encrypted_key)
        if (!encrypted_value) {
            return null;
        }
        return CryEnc.decrypt(encrypted_value)
    } catch (error) {
        if (process.env.NODE_ENV === "development")
            console.error(error)
        return null
    }
}

function appStoreRemove(key) {
    try {
        let encrypted_key = CryEnc.encrypt(key)
        localStorage.removeItem(encrypted_key)
    } catch (error) {
        if (process.env.NODE_ENV === "development")
            console.error(error)
    }
}

module.exports = {
    appStoreSet, appStoreGet, appStoreRemove
}
