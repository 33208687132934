import { combineReducers } from "redux";
import auth from "./auth/reducers";
import count from "./count/reducers";
import loans from "./loans/reducers";
import orders from "./orders/reducers";

export default combineReducers({
    auth,
    count,
    orders,
    loans
});
