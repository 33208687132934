import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { blue, grey, red } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";

const env = runtimeEnv();
const transparent_logo_path =
  env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = env.REACT_APP_PRIMARY_COLOR || "#640032";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  root: {
    flexGrow: 1,
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  primary_button: {
    background: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
    fontSize: "18px",
  },
  orders_date: {
    color: grey[700],
    float: "right",
  },
  orders_items: {
    borderBottom: "1px solid",
    borderBottomColor: grey[400],
  },
  success_btn: {
    backgroundColor: "#3BB719",
    color: grey[50],
    borderRadius: "20px",
    fontSize: "18px",
    textTransform: "none",
  },
  closed_btn: {
    backgroundColor: grey[500],
    borderRadius: "20px",
    fontSize: "18px",
    textTransform: "none",
  },
  pending_btn: {
    backgroundColor: blue[500],
    borderRadius: "20px",
    fontSize: "18px",
    textTransform: "none",
  },
  arrears_btn: {
    backgroundColor: red[500],
    color: grey[50],
    borderRadius: "20px",
    fontSize: "18px",
    textTransform: "none",
  },
  no_decoration: {
    textDecoration: "none",
  },
  bottom_buttons: {
    fontSize: "18px",
    fontTransformation: "none",
  },
});

function LoanDetails(props) {
  const loan = useSelector((state) => state.loans.loan);
  const { classes } = props;
  const history = useHistory();
  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const retailer = retailer_data
  const corporate_settings = retailer_data.corporate_settings

  const getCorporateSetting = (key, property) => {
    return corporate_settings && corporate_settings[key] && corporate_settings[key][property];
  }

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const repayLoan = () => {
    if (["Active", "Arrears", "Written_off"].includes(loan.loan_status)) {
      return (
        <Container>
          <Link to="/repay_loan" className={classes.no_decoration}>
            <Button
              variant="contained"
              className={classes.primary_button}
              fullWidth
            >
              {retailer_data.is_la_riba === true ? 'Repay facility' : 'Repay loan'}
            </Button>
          </Link>
        </Container>
      );
    } else {
      return (
        <Container>
          <Link to="/" className={classes.no_decoration}>
            <Button
              variant="outlined"
              color="white"
              className={classes.bottom_buttons}
              fullWidth
            >
              Back Home
            </Button>
          </Link>
        </Container>
      );
    }
  };

  const loanStatus = () => {
    if (["Active"].includes(loan.loan_status)) {
      return (
        <Grid container justify="center">
          <Button
            variant="contained"
            className={classes.success_btn}
            disableElevation={true}
          >
            {loan.loan_status}
          </Button>
        </Grid>
      );
    } else if (["Arrears", "Written_off"].includes(loan.loan_status)) {
      return (
        <Grid container justify="center">
          <Button
            variant="contained"
            className={classes.arrears_btn}
            disableElevation={true}
          >
            Arrears
          </Button>
        </Grid>
      );
    } else if (loan.loan_status === "Closed") {
      return (
        <Grid container justify="center">
          <Button
            variant="contained"
            color="secondary"
            className={classes.closed_btn}
            disableElevation={true}
          >
            {loan.loan_status}
          </Button>
        </Grid>
      );
    } else {
      return (
        <Grid container justify="center">
          <Button
            variant="contained"
            color="secondary"
            className={classes.pending_btn}
            disableElevation={true}
          >
            {loan.loan_status.replaceAll('_', ' ')}
          </Button>
        </Grid>
      );
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <IconButton
                className={classes.iconButton}
                aria-label="directions"
                color="primary"
                onClick={() => history.goBack()}
                disableRipple
              >
                <ArrowBackIosIcon />
              </IconButton>
              <Typography className={classes.input} align="center">
                <b>{retailer_data.is_la_riba === true ? 'Facility ' : 'Loan '} No. {loan.loan_id}</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>
          <br />
          <br />
          {loanStatus()}
          <br />
          <List
            component="nav"
            style={{ marginBottom: "60px", borderRadius: "0px" }}
          >
            {loan["invoice_url"] &&
            <> <ListItem>
              <ListItemText
                primary={
                  <Grid container className={classes.root}>
                    <Grid xs={6} item>
                      <Typography variant="body1">Invoice</Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <Typography
                        variant="body1"
                        className={classes.orders_date}
                      >
                        <a
                          href={loan["invoice_url"]}
                          rel="noreferrer"
                          target="_blank"
                          style={{
                            "text-decoration": "none",
                          }}
                        >
                          {loan["invoice_name"]}
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
            <Divider />
            </>}

            {loan.loan_status === "Pending_confirmation" && (
              <>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography variant="body1">
                            Amount applied for
                          </Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {toCurrency(loan["amount"])}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography variant="body1">
                            Application date
                          </Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {loan["application_date"]}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <Divider />
              </>
            )}
            {loan.loan_status !== "Pending_confirmation" && (
              <>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography variant="body1">Principal</Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {toCurrency(loan.data["principalBalance"])}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <Divider />

                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography variant="body1">
                          {retailer_data.is_la_riba === true ? 'Accrued daily profit' : 'Accrued daily interest'}
                          </Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {toCurrency(loan.data["interestBalance"])}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>

                <Divider />

                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography variant="body1">Fees</Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {toCurrency(loan.data["feesBalance"])}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>

                <Divider />

                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography variant="body1">Created on</Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {loan.disbursement_date}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>

                <Divider />

                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography variant="body1">Due date</Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {loan.due_on}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>

                <Divider />

                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography variant="body1">Amount paid</Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {toCurrency(loan.balance)}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>

                <Divider />

                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography
                            style={{ fontWeight: "700" }}
                            variant="body1"
                          >
                            Current balance
                          </Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            style={{ fontWeight: "700" }}
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {toCurrency(loan.balance)}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <Divider />
              </>
            )}
          </List>
          {/** Show repay button on each individual loans for corporates that does not allow having multiple loans */}
          {getCorporateSetting(retailer.distributor_loan_days, "allow_multiple_loans") === false && repayLoan()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(LoanDetails);
