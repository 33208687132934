import React, {StrictMode} from "react";
import ReactDOM from "react-dom";
import {Main} from "./Main";
import { Provider } from "react-redux";
import store from "./store/store";
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";

ReactDOM.render(
    <StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <HttpsRedirect>
                    <Main/>
                </HttpsRedirect>
            </BrowserRouter>
        </Provider>
    </StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
