export function login(retailer_data) {
    return {
        type: "@auth/LOGIN",
        payload: retailer_data
    };
}

export function logout() {
    return {
        type: "@auth/LOGOUT"
    };
}

export function session_expired() {
    return {
        type: "@auth/SESSION_EXPIRED"
    };
}

export function update_retailer(retailer_data) {
    return {
        type: "@auth/UPDATE_RETAILER",
        payload: retailer_data
    };
}

