import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Chip, Paper } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import SearchIcon from "@material-ui/icons/Search";
import * as Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { set_loan } from "../store/modules/loans/actions";
import { authorization_headers } from "../utils/api_authorization";
import { decrypt } from "../utils/crypto_encryption";
import { appStoreSet } from "../utils/app_storage";
import { Skeleton } from "@material-ui/lab";

const env = runtimeEnv();
const transparent_logo_path =
  env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = env.REACT_APP_PRIMARY_COLOR || "#640032";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  root: {
    flexGrow: 1,
  },
  toolbar: {
    border: "1px solid",
    borderColor: grey[300],
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  todays_balance_card: {
    marginTop: '30px',
    marginBottom: '30px',
    backgroundColor: "#651e33",
    color: "#fff",
    borderRadius: 15
  },
  todays_balance_card_title: {
    paddingTop: "15px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "0.066px"
  },
  todays_balance_total: {
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "60px",
    letterSpacing: "0.131px"
  },
  todays_balance_card_description: {
    color: "#ffffff",
    fontSize: "16px",
    fontStyle: "normal",
    textAlign: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontWeight: "600",
    lineHeight: "22px",
    letterSpacing: "0.066px"
  },
  todays_balance_card_button: {
    marginTop: "30px",
    marginBottom: "20px",
    display: "flex",
    color: "#651E33",
    textAlign: "center",
    fontSize: '14px',
    fontWeight: "600",
    textTransform: "none",
    fontStyle: 'normal',
    padding: "5px 10px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    '&:hover': {
      background: "#f44336",
      color: "#ffffff",
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  search_input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  orders_date: {
    color: grey[700],
    float: "right",
  },
  orders_items: {
    borderBottom: "1px solid",
    borderBottomColor: grey[400],
  },
  remove_link_decoration: {
    color: "inherit",
    textDecoration: "inherit",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  no_decoration: {
    textDecoration: "none",
  },
  delivered_btn_sm: {
    backgroundColor: "#2F9FFF",
    color: grey[50],
    borderRadius: "20px",
    fontSize: "10px",
    textTransform: "unset",
  },
});

function LoanHistory(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [loans, setLoans] = useState([]);
  const [search_results, setSearchResults] = useState([]);
  const [search_value, setSearchValue] = useState("");
  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const retailer = retailer_data;
  const corporate_settings = retailer.corporate_settings

  const getCorporateSetting = (key, property) => {
    return corporate_settings && corporate_settings[key] && corporate_settings[key][property];
  }

  useEffect(() => {
    fetchLoans();
  }, []);

  const fetchLoans = () => {
    setLoading(true);

    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login');
    }

    fetch(env.REACT_APP_SERVER_API_URL + "/loans/loans", {
      headers: { ...authorization_headers(), ...{ Authorization: token } },
    })
      .then((res) => res.text())
      .then((res) => {
        let data = JSON.parse(decrypt(res));
        setLoans(data);
        setSearchResults(data);
        setLoading(false);
        console.log(data)
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter((value) => {
      return (
        value.order_id?.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.created_on?.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.payment_mode?.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };

  const handleOnChange = async (e) => {
    let value = e.target.value;
    setSearchValue(value);
    if (value.length > 2) {
      let results = await arraySearch(loans, value);
      setSearchResults(results);
    } else {
      setSearchResults(loans);
    }
  };

  const cancelSearch = () => {
    setSearchResults(loans);
    setSearchValue("");
  };

  const selectLoan = (loan) => {
    dispatch(set_loan(loan));
    history.push("/loan_details");
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const skeletonsLoaders = () => {
    return (
      <>
        <Skeleton height={118} />
        <Skeleton animation={false} height={118} />
        <Skeleton animation="wave" height={118} />
        <Skeleton height={118} />
        <Skeleton animation="wave" height={118} />
      </>
    )
  }

  const noRecords = () => {
    if (loans.length <= 0 && loading === false) {
      return (
        <>
          <Container style={{ marginTop: "60px", borderRadius: "0px" }}>
            <Typography align="center" variant="subtitle1">
              No pending loans.
            </Typography>

            <br />
            <br />
            <br />
            <Link to="/" className={classes.no_decoration}>
              <Button
                variant="outlined"
                className={classes.bottom_buttons}
                color="primary"
                fullWidth
              >
                Back Home
              </Button>
            </Link>
          </Container>
        </>
      );
    }
  };

  const statusBadge = (order) => {
    let status = null;
    if (order.loan_status === "Pending_confirmation") {
      status = "Pending Confirmation";
    } else {
      status = order.loan_status;
    }

    if (order.status === "flagged") {
      return (
        <Button
          variant="contained"
          className={classes.delivered_btn_sm}
          style={{ backgroundColor: "#ff9f00" }}
          disableElevation={true}
        >
          {status}
        </Button>
      );
    } else if (order.status === "fail_payment") {
      return (
        <Chip label={status} color="error" style={{ backgroundColor: "#b50000", color: 'white' }} />
      );
    } else {
      return (
        <Chip label={status} color="error" style={{ backgroundColor: "#2F9FFF", color: 'white' }} />
      );
    }
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const repayMultipleLoans = () => {
    history.push("/repay_loans");
  };

  const loans_balance = (loans) => {
    let sum = loans.reduce(function(prev, current) {
      return prev + +current.balance
    }, 0);
    return sum;
  };

  const total_loans_balance = loans_balance(loans);

  const balanceView = () => {
    if (getCorporateSetting(retailer.distributor_loan_days, "allow_multiple_loans") === true) {
      appStoreSet("has_active_loan", true);
      return (
        <Container className={classes.page_container}>
          <Grid container justify="center" className={classes.todays_balance_card}>
            <Grid container justify="center">
              <Typography
                className={classes.todays_balance_card_title}
              >
                My {retailer_data.is_la_riba === true ? 'facility' : 'loan'} balance
              </Typography>
            </Grid>
            <Grid container justify="center">
              <Typography variant="h4" className={classes.todays_balance_total}>
                {toCurrency(total_loans_balance)}
              </Typography>
            </Grid>
            <Grid container justify="center">
              <Typography
                variant="h6"
                className={classes.todays_balance_card_description}
              >
                Today's due balance.
              </Typography>
            </Grid>
            <Grid container justify="center">
              <Button
                align="center"
                type="submit"
                disabled={total_loans_balance <= 0 ? true : false}
                className={classes.todays_balance_card_button}
                onClick={repayMultipleLoans}
              >
                {retailer_data.is_la_riba === true ? 'Repay facility' : 'Repay loan'}
              </Button>
            </Grid>
          </Grid>
        </Container>
      )
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography className={classes.input} align="center">
                <b>
                  {retailer_data.is_la_riba === true ? 'Facility history' : 'Loan history'}
                </b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>

          { !loading && <AppBar position="sticky" color="white" style={{ boxShadow: "none" }}>
            <Toolbar className={classes.toolbar}>
              <IconButton style={{ padding: 10 }} aria-label="directions">
                <SearchIcon />
              </IconButton>
              <InputBase
                value={search_value}
                onChange={handleOnChange}
                className={classes.search_input}
                placeholder="Search history..."
              />
              <Button
                style={{ color: grey[600] }}
                aria-label="directions"
                onClick={cancelSearch}
              >
                Cancel
              </Button>
            </Toolbar>
          </AppBar> }
          { !loading && noRecords()}
          { !loading && <Container style={{ padding: "0", maxHeight: `calc(100vh - 128px)`, overflow: 'auto' }}>
            <List
              style={{
                borderRadius: "0px",
                backgroundColor: "white",
              }}
            >
              {search_results.map((loan, index) => (
                <ListItem
                  key={index}
                  className={classes.orders_items}
                  onClick={() => selectLoan(loan)}
                  button
                >
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={4} item>
                          <Typography variant="h7">{loan.enc_id}</Typography>
                        </Grid>
                        <Grid xs={3} item>
                          <Typography
                            variant="h7"
                            className={classes.orders_date}
                          >
                            {loan.disbursement_date ? loan.disbursement_date : '_'}
                          </Typography>
                        </Grid>
                        <Grid xs={5} item>
                          <Typography
                            variant="h7"
                            className={classes.orders_date}
                          >
                            {statusBadge(loan)}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Container> }
          {loading && skeletonsLoaders()}

          {progressLoader()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(LoanHistory);
