import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Container, Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import React, { Fragment, useState } from "react";
import * as Icon from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import { login } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreSet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";

const env = runtimeEnv();

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
  ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  welcome_message: {
    color: "white",
    fontSize: "32px",
  },
  welcome_message_sub: {
    color: "white",
    fontSize: "20px",
  },
  logo: {
    height: "100px",
    width: "100px",
    backgroundRepeat: "no-repeat",
    marginTop: "80px",
    backgroundImage: `url(${env.REACT_APP_LOGO})`,
  },
  primary_button: {
    background: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
  },
  primary_links: {
    color: env.REACT_APP_PRIMARY_COLOR,
    textDecoration: "none",
  },
  page_container: {
    marginTop: "20px",
    paddingTop: "40px",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  fields_toolbar: {
    borderColor: "#C9C9C9",
    border: "1px solid",
    borderRadius: "4px",
    paddingLeft: "8px",
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
    },
  },
  input_label: {
    marginBottom: "2px",
  },
  order_now_btn: {
    width: "60%",
  },
  no_decoration: {
    textDecoration: "none",
    color: "inherit !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

function Login(props) {
  const signed = useSelector((state) => state.auth.signed);
  const dispatch = useDispatch();

  const { classes, history } = props;
  const [state, setState] = useState(new Map());

  const [loading, setLoading] = React.useState(false);
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);

  if (signed) {
    return <Redirect to="/" />;
  }

  const upsert = (key, value) => {
    setState((prev) => new Map(prev).set(key, value));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    upsert(name, value);
  };

  const setCookie = (token) => {
    // Cookie should exipire in 5 minutes
    var expite_at = new Date(new Date().getTime() + 5 * 60 * 1000);
    Cookies.remove("AbsaAgiza");
    Cookies.set("AbsaAgiza", token, { expires: expite_at, secure: true });
  };

  const isValidated = () => {
    if (!state.get("phone_number")) {
      setSnackbarMessage("Phone number cannot be blank.");
      openSnackbar(true);
      return false;
    } else if (!state.get("pin")) {
      setSnackbarMessage("Pin cannot be blank.");
      openSnackbar(true);
      return false;
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isValidated()) {
      return;
    }

    let params = {
      phone_number: state.get("phone_number"),
      pin: state.get("pin"),
    };

    setLoading(true);

    fetch(env.REACT_APP_SERVER_API_URL + "/retailers/login", {
      method: "POST",
      headers: authorization_headers(),
      body: encrypt(JSON.stringify(params)),
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              let data = JSON.parse(decrypt(text));
              if (data.require_otp === true) {
                appStoreSet("allow_retailer_otp_login", true);
                appStoreSet("retailer_id", data.retailer_id);
                setLoading(false)
                openSnackbar(false)
                history.push("/login_otp");
              } else {
                setCookie(data.token);
                let retailer_data = data.retailer;
                appStoreSet("retailer_data", JSON.stringify(retailer_data));
                dispatch(login(retailer_data));
                setLoading(false)
                openSnackbar(false)
                history.push("/");
              }
            } else {
              setSnackbarMessage("Invalid phone number or PIN");
              setSnackbarSeverity("warning");
              openSnackbar(true);
              // dispatch(login({}))
            }
            setLoading(false);
          })
          .catch(() => {
            setSnackbarMessage(
              "Error submitting information. Please retry or contact support."
            );
            openSnackbar(true);
            setLoading(false);
          });
      })
      .catch(() => {
        setSnackbarMessage(
          "Error submitting information. Please retry or contact support."
        );
        openSnackbar(true);
        setLoading(false);
      });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            background: `url(${env.REACT_APP_BG_IMAGE})`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Grid
            position="fixed"
            style={{ boxShadow: "none", backgroundColor: "white" }}
          >
            <Toolbar>
              <Avatar
                src={env.REACT_APP_LOGO}
                variant="square"
                className={classes.square}
              />
            </Toolbar>
          </Grid>
          <Grid
            container
            style={{
              width: "100%",
              maxWidth: "400px",
              marginTop: "90px",
              padding: "10px",
            }}
          >
            <Grid container justify="center" className={classes.root}>
              <Grid>
                <Grid container justify="center">
                  <Grid />
                  <Grid>
                    <Typography
                      variant="h5"
                      className={classes.welcome_message}
                    >
                      Welcome to {env.REACT_APP_ORGANISATION_NAME}
                    </Typography>
                    <Typography
                      variant="h5"
                      className={classes.welcome_message_sub}
                    >
                      Order stock anytime. Get instant financing.
                    </Typography>
                  </Grid>
                  <Grid />
                </Grid>
              </Grid>
            </Grid>
            <Container justify="center" className={classes.page_container}>
              <form onSubmit={handleSubmit}>
                <Typography variant="body1" className={classes.input_label}>
                  Phone number
                </Typography>
                <Toolbar className={classes.fields_toolbar}>
                  <InputAdornment position="start">
                    <Icon.Phone style={{ color: "#a19f9f" }} />
                  </InputAdornment>
                  <Divider orientation="vertical" flexItem />
                  <InputBase
                    className={classes.input}
                    placeholder="Enter phone number"
                    name="phone_number"
                    value={state.get("phone_number")}
                    onChange={handleChange}
                  />
                </Toolbar>
                <br />
                <Typography variant="body1" className={classes.input_label}>
                  PIN
                </Typography>
                <Toolbar className={classes.fields_toolbar}>
                  <InputAdornment position="start">
                    <Icon.Lock style={{ color: "#a19f9f" }} />
                  </InputAdornment>
                  <Divider orientation="vertical" flexItem />
                  <InputBase
                    className={classes.input}
                    placeholder="Enter PIN"
                    name="pin"
                    value={state.get("pin")}
                    type="password"
                    onChange={handleChange}
                  />
                </Toolbar>
                <br />
                <Grid container justify="center">
                  <Button
                    variant="contained"
                    align="center"
                    size="large"
                    fullWidth={true}
                    type="submit"
                    className={classes.primary_button}
                  >
                    <b>Login</b>
                  </Button>
                </Grid>
              </form>
              <br />
              <Grid container justify="center" style={{ marginTop: "7px" }}>
                <Typography variant="body1">
                  <Link
                    to="/register_initiate"
                    className={classes.primary_links}
                  >
                    Register
                  </Link>
                </Typography>
              </Grid>
              <br />
              <Grid container justify="center">
                <Typography variant="body1">
                  <Link
                    to="/forgot_pin_initiate"
                    className={classes.primary_links}
                  >
                    Forgot PIN
                  </Link>
                </Typography>
              </Grid>

              <br />
              <Grid container justify="center">
                <Typography variant="caption">
                  {env.REACT_APP_FOOTER}
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography variant="caption">
                  Powered by{" "}
                  <a
                    href="https://agiza.io/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.primary_links}
                  >
                    Agiza.io
                  </a>
                </Typography>
              </Grid>
              <br />
            </Container>

            {snackbarLoader()}
            {progressLoader()}
          </Grid>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(Login);
