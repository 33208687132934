import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { HistoryOutlined } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import RestoreIcon from "@material-ui/icons/Restore";
import SettingsIcon from "@material-ui/icons/Settings";
import * as Cookies from "js-cookie";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { logout, session_expired } from "../store/modules/auth/actions";
import { set_loan } from "../store/modules/loans/actions";
import { set_order } from "../store/modules/orders/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreRemove, appStoreSet } from "../utils/app_storage";
import { decrypt } from "../utils/crypto_encryption";

const env = runtimeEnv();

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  button_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: "0",
    paddingRight: "0",
  },
  page_container: {
    paddingTop: "100px",
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  order_now_btn: {
    marginTop: "80px",
    width: "60%",
  },
  primary_button: {
    marginTop: "10px",
    background: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    // width: "60%",
  },
  primary_button_100: {
    marginTop: "10px",
    background: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
  },
  outlined_button: {
    marginTop: "10px",
    color: env.REACT_APP_PRIMARY_COLOR,
    borderColor: "#64003226",
    // width: "60%",
  },
  word_button: {
    marginTop: "10px",
    width: "60%",
    color: env.REACT_APP_PRIMARY_COLOR,
    backgroundColor: "#64003226",
    border: "none",
    boxShadow: "none",
  },
  repay_loan_btn: {
    marginTop: "10px",
    width: "60%",
  },
  repay_loan_order_now_btn: {
    marginTop: "10px",
    width: "60%",
  },
  top_headers: {
    fontWeight: 300,
    fontSize: "large",
  },
  stickToBottom: {
    bottom: 0,
    backgroundColor: "#fafafa",
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
    position: "fixed",
  },
  button: {
    height: 95, // setting height/width is optional
    textTransform: "none",
    fontSize: "18px",
  },
  label: {
    // Aligns the content of the button vertically.
    flexDirection: "column",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing.unit,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  no_right_borders: {
    borderRight: "none",
  },
  floating_buttons: {
    boxShadow: "none",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
  },
  user_menu_icon: {
    marginRight: "5px",
  },
  no_decoration: {
    textDecoration: "none",
    color: "inherit !important",
  },
});

function FmcgHome(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const retailer = retailer_data;
  const order = useSelector((state) => state.orders.order);
  const loan = useSelector((state) => state.orders.order.loan);
  const [user_menu_opened, openUserMenu] = React.useState(null);
  const [pending_orders, setPendingOrders] = useState([]);
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(false);
  const corporate_settings = retailer.corporate_settings

  appStoreRemove("approved_order_id");
  appStoreRemove("selected_credit_card");

  const getCorporateSetting = (key, property) => {
    return corporate_settings && corporate_settings[key] && corporate_settings[key][property];
  }

  useEffect(() => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login')
    }

    fetch(env.REACT_APP_SERVER_API_URL + "/orders/active_loan", {
      headers: { ...authorization_headers(), ...{ Authorization: token } },
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              let data = JSON.parse(decrypt(text));
              dispatch(set_order(data));
              dispatch(set_loan(data.loan));
            } else {
            }
          })
          .catch(() => {});
      })
      .catch(() => {});


    setLoading(true);
    fetch(env.REACT_APP_SERVER_API_URL + "/loans/active_loans", {
      headers: { ...authorization_headers(), ...{ Authorization: token } },
    })
      .then((res) => res.text())
      .then((res) => {
        // The loan created first is the first loan in the array
        let data = JSON.parse(decrypt(res));
        setLoans(data);
        if (getCorporateSetting(retailer.distributor_loan_days, "allow_multiple_loans") === true) {
          let immediate_due_loan =  data[0]
          dispatch(set_loan(immediate_due_loan))
        }
        setLoading(false);
      })
      .catch(() => {
      });

    fetch(env.REACT_APP_SERVER_API_URL + "/orders/pending", {
      headers: { ...authorization_headers(), ...{ Authorization: token } },
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              let data = JSON.parse(decrypt(text));
              setPendingOrders(data);
            } else {
            }
          })
          .catch(() => {});
      })
      .catch(() => {});
  }, [dispatch]);

  const userMenuClicked = (event) => {
    openUserMenu(event.currentTarget);
  };

  const closeUserMenu = () => {
    openUserMenu(null);
  };

  const logOut = () => {
    Cookies.remove("AbsaAgiza");
    dispatch(logout());
    history.push("/login");
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const userMenu = () => {
    return (
      <div>
        <IconButton
          aria-controls="user-menu"
          aria-haspopup="true"
          className={classes.iconButton}
          aria-label="directions"
          onClick={userMenuClicked}
        >
          <MoreHorizIcon />
        </IconButton>

        <Menu
          id="user-menu"
          anchorEl={user_menu_opened}
          keepMounted
          open={Boolean(user_menu_opened)}
          onClose={closeUserMenu}
        >
          <Link to="/change_pin" className={classes.no_decoration}>
            <MenuItem>
              <SettingsIcon className={classes.user_menu_icon} />
              Change PIN
            </MenuItem>
          </Link>
          <Divider />
          <MenuItem onClick={logOut}>
            <ExitToAppIcon className={classes.user_menu_icon} />
            Logout
          </MenuItem>
        </Menu>
      </div>
    );
  };

  const financeLimit = () => {
    let limit = retailer.finance_limit;

    if (!limit) {
      return 0;
    }
    return limit;
  };

  const newOrder = () => {
    history.push("/ordering");
  };

  const invoiceOrder = () => {
    history.push("/invoice_order");
  };

  const repayLoan = () => {
    history.push("/loan_details");
  };

  const repayMultipleLoans = () => {
    history.push("/repay_loans");
  };

  const daysRemaining = () => {
    // if (order.loan.days_remaining) {
    //     if (order.loan.days_remaining < 0) {
    //         return -(order.loan.days_remaining) + " days past due"
    //     } else {
    //         return order.loan.days_remaining + " days remaining"
    //     }
    // } else {
    //     return "You have a loan pending disbursement"
    // }

    if (order.loan.days_remaining > 0 || order.loan.days_remaining === 0) {
      return order.loan.days_remaining + " days remaining";
    } else if (order.loan.days_remaining < 0) {
      return -order.loan.days_remaining + " days past due";
    } else {
      let loan = retailer_data.is_la_riba === true ? 'facility' : 'loan'
      return "You have a "+loan+" pending disbursement";
    }
  };

  const loan_in_arrears = (loans) => {
    const loanInArreas = loans.find(loan => {
      return loan.days_remaining < 0;
    });

    return loanInArreas === undefined ? false : true;

  };

  const any_loan_in_arrears = loan_in_arrears(loans);


  const loans_balance = (loans) => {
    let sum = loans.reduce(function(prev, current) {
      return prev + +current.balance
    }, 0);
    return sum;
  };

  const total_loans_balance = loans_balance(loans);

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const mainView = () => {
    if (getCorporateSetting(retailer.distributor_loan_days, "allow_multiple_loans") === false &&
      order.loan &&
      order.loan.id &&
      (order.loan.days_remaining > 0 ||
        order.loan.days_remaining === 0 ||
        order.loan.days_remaining < 0)
    ) {
      appStoreSet("has_active_loan", true);
      return (
        <Container className={classes.page_container}>
          <Grid container justify="center">
            <Typography
              variant="h6"
              className={classes.top_headers}
              gutterBottom
            >
              Current {retailer_data.is_la_riba === true ? 'facility' : 'loan'} balance
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Typography variant="h4" gutterBottom>
              {toCurrency(order.loan.balance)}
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Typography
              variant="h6"
              className={classes.top_headers}
              gutterBottom
            >
              {daysRemaining()}
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Button
              variant="outlined"
              align="center"
              size="large"
              fullWidth={true}
              color="primary"
              type="submit"
              className={classes.primary_button}
              style={{ width: "100%" }}
              onClick={repayLoan}
            >
              <b>{retailer_data.is_la_riba === true ? 'Repay facility' : 'Repay loan'}</b>
            </Button>
            {getCorporateSetting(retailer.distributor_loan_days, "normal_ordering") === true && (
              <Button
                variant="outlined"
                align="center"
                size="large"
                fullWidth={true}
                type="submit"
                className={classes.word_button}
                onClick={newOrder}
                style={{ width: "100%" }}
                disabled
              >
                <b>New Order</b>
              </Button>
            )}
          </Grid>
        </Container>
      );
    } else if (loan && order.loan.id) {
      appStoreSet("has_active_loan", true);
      return (
        <Container className={classes.page_container}>
          <Grid container justify="center">
            <Typography
              variant="h6"
              className={classes.top_headers}
              gutterBottom
            >
              {daysRemaining()}
            </Typography>
          </Grid>
          {getCorporateSetting(retailer.distributor_loan_days, "normal_ordering") === true && (
            <Grid container justify="center">
              <Button
                variant="contained"
                align="center"
                size="large"
                fullWidth={true}
                color="primary"
                type="submit"
                className={classes.primary_button}
                onClick={newOrder}
              >
                <b>New Order</b>
              </Button>
            </Grid>
          )}
        </Container>
      );
    } else {
      appStoreSet("has_active_loan", false);
      return (
        <Container className={classes.page_container}>
          <Grid container justify="center">
            <Typography
              variant="h6"
              className={classes.top_headers}
              gutterBottom
            >
              Hello, your finance limit is
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Typography variant="h4" gutterBottom>
              {toCurrency(financeLimit())}
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Typography
              variant="h6"
              className={classes.top_headers}
              gutterBottom
            >
              Order stock anytime. Get instant financing.
            </Typography>
          </Grid>
          <Container className={classes.button_container}>
            {getCorporateSetting(retailer.distributor_loan_days, "invoice_financing") === true && (
              <Button
                variant="contained"
                align="center"
                size="large"
                fullWidth={true}
                type="submit"
                className={classes.primary_button}
                onClick={invoiceOrder}
                style={{ marginRight: "10px" }}
              >
                <b>Finance Order</b>
              </Button>
            )}

            {getCorporateSetting(retailer.distributor_loan_days, "normal_ordering") === true && (
              <Button
                variant="outlined"
                align="center"
                size="large"
                fullWidth={true}
                type="submit"
                className={classes.outlined_button}
                onClick={newOrder}
              >
                <b>New Order</b>
              </Button>
            )}
          </Container>
        </Container>
      );
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              {userMenu()}
              <Typography className={classes.input} align="center">
                <b>{env.REACT_APP_ORGANISATION_NAME}</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>

          {mainView()}


          {getCorporateSetting(retailer.distributor_loan_days, "normal_ordering") === true && (
            <Grid
              className={`${classes.stickToBottom} ${classes.contained_width}`}
            >
              <ButtonGroup
                variant="contained"
                className={classes.floating_buttons}
              >
                <Link
                  to="/order_history"
                  style={{ borderRight: "none", textDecoration: "none" }}
                >
                  <Button
                    style={{ borderRight: "none" }}
                    classes={{ root: classes.button, label: classes.label }}
                  >
                    <RestoreIcon className={classes.icon} />
                    <Typography
                      variant="h6"
                      className={classes.top_headers}
                      gutterBottom
                    >
                      Order History
                    </Typography>
                  </Button>
                </Link>

                <Link to="/pending_orders" className={classes.no_decoration}>
                  <Button
                    variant="raised"
                    classes={{ root: classes.button, label: classes.label }}
                  >
                    <Badge badgeContent={pending_orders.length} color="error">
                      <LocalShippingIcon className={classes.icon} />
                    </Badge>
                    <Typography
                      variant="h6"
                      className={classes.top_headers}
                      gutterBottom
                    >
                      Pending Orders
                    </Typography>
                  </Button>
                </Link>
              </ButtonGroup>
            </Grid>
          )}
          {progressLoader()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(FmcgHome);
