import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from "@material-ui/core/Button";
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Snackbar from "@material-ui/core/Snackbar";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import IconButton from '@material-ui/core/IconButton';
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import runtimeEnv from '@mars/heroku-js-runtime-env';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';
import CommonStyles from '../common/commonStyles';
import HeaderBar from '../common/header_bar';
import "../styles/styles.css";
import { appStoreRemove } from '../utils/app_storage';

const env = runtimeEnv();

const primary_main_color = env.REACT_APP_PRIMARY_COLOR || '#640032'
const transparent_logo_path = env.REACT_APP_TRANSPARENT_LOGO_PATH || '/assets/images/logo_transparent.png'

const appTheme = createMuiTheme({
    palette: {
        primary: {
            main: env.REACT_APP_PRIMARY_COLOR,
        },
    },
    typography: {
        fontFamily: ['Source Sans Pro'].join(','),
    },
});

const payment_successful = '/assets/images/payment_successful.png'
const payment_failed = '/assets/images/payment_failed.png'

function Alert(props) {
    return <MuiAlert elevation={6} variant="outlined" {...props} />
}

const styles = theme => ({
    ...CommonStyles(theme),
    shopping_cart_container: {
        paddingTop: "70px",
        // backgroundColor: '#ffffff !important',
        paddingBottom: "100px",
        minHeight: 'var(--doc-height)',
        '& .MuiTabs-flexContainer': {
            justifyContent: "center",
            borderRadius: '4px',
            marginTop: "30px",
        },
        '& .MuiTab-root': {
            // backgroundColor: '#ffffff',
            color: primary_main_color,
            border: '1px solid #F0397B',
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: '#ffffff',
            backgroundColor:primary_main_color,
        },
        '& .MuiListItem-gutters': {
            paddingLeft: '0px',
            paddingRight: '0px',
        },
        '& .MuiListItem-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
        },
        '& .MuiTab-wrapper': {
            // padding: "0px 10px"
        },
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent !important',
        },
    },
    payment_successful_icon: {
        position: 'absolute',
        top: '254px',
        left: '0px',
        right: '0px',
        width: "90px",
        height: "90px",
        margin: 'auto',
    },
    payment_successful_content: {
        position: 'absolute',
        top: '340px',
        left: '0px',
        right: '0px',
        width: '320px',
        margin: 'auto',
    },
    payment_status_title: {
        textAlign: 'center',
        fontSize: '20px',
        fontWeight: '400',
        paddingTop: '15px',
    },
    iconButton: {
        padding: 10,
        color: 'white',
    },
    payment_status_sub_title: {
        textAlign: 'center',
        fontSize: '18px',
        color: '#565656',
        paddingTop: '32px',
        '& a': {
            color: primary_main_color,
            textDecoration: 'none',
        },
    },

    payment_status_text: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '12px',
        color: '#565656',
        paddingTop: '2px',
    },

    payment_status_text_failed: {
        textAlign: 'center',
        fontWeight: '600',
        fontSize: '12px',
        color: '#565656',
        paddingTop: '32px',
        '& span a': {
            color: primary_main_color,
            textDecoration: 'none',
        }
    },

    alert_override: {
        "&": {
            borderLeftWidth: '4px',
            borderTop: 'none',
            borderBottom: 'none',
            borderRight: 'none',
            boxShadow: '0px 2px 7px rgba(0, 0, 0, 0.1)',
            width: '100%',
            backgroundColor: 'white'
        },
        '& .MuiAlert-message': {
            color: '#5B4C4B'
        }
    },
    btn_primary: {
        position: 'absolute',
        top: '587px',
        left: '0',
        right: '0',
        margin: 'auto',
        width: '183px',
        padding: '10px 0px',
        backgroundColor: primary_main_color,
        color: 'white',
        fontSize: '12px',
        textTransform: 'capitalize',
        cursor: 'pointer',
        boxShadow: 'none !important',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: primary_main_color,
        },
        '& .MuiButton-label': {
            textTransform: 'none !important',
        },
    },
    btn_primary_failed: {
        position: 'absolute',
        top: '587px',
        left: '0',
        right: '0',
        margin: 'auto',
        width: '96px',
        padding: '10px 0px',
        backgroundColor: primary_main_color,
        color: 'white',
        fontSize: '12px',
        textTransform: 'capitalize',
        cursor: 'pointer',
        boxShadow: 'none !important',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: primary_main_color,
        },
        '& .MuiButton-label': {
            textTransform: 'none !important',
        },
    },
    info_label: {
        color: 'black',
        fontSize: '18px'
    },
    no_decoration: {
        textDecoration: 'none'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    title_input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: '18px',
        color: 'white'
    },
});

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CyberSourcePaymentStatus(props) {
    const history = useHistory()
    const {classes} = props

    const [open_snackbar, openSnackbar] = React.useState(false)
    const [snackbar_severity, setSnackbarSeverity] = React.useState('warning')
    const [snackbar_message] = React.useState(null)

    const query = useQuery();
    const payment_status = query.get('status')
    appStoreRemove('selected_credit_card')

    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        openSnackbar(false)
        setSnackbarSeverity('warning')
    }

    const snackbarLoader = () => {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                className={classes.snackbar}
                open={open_snackbar}>
                <Alert onClose={() => closeSnackbar()} severity={snackbar_severity} className={classes.alert_override}>
                    {snackbar_message}
                </Alert>
            </Snackbar>
        )
    }

    const goBack = () => {
        history.push('/')
    }

    return (
        <ThemeProvider theme={appTheme}>
            <CssBaseline/>
            <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
                <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Link to='/' className={classes.no_decoration}>
                        <IconButton className={classes.iconButton} aria-label="directions" color="primary"
                                    disableRipple>
                            <ArrowBackIosIcon/>
                        </IconButton>
                    </Link>
                    <Typography className={classes.title_input} align="center">
                        <b>Card Selection</b>
                    </Typography>
                    <Link to='/' className={classes.no_decoration}>
                        <Avatar src={env.REACT_APP_TRANSPARENT_LOGO} variant="square" className={classes.square}/>
                    </Link>
                </Toolbar>
            </HeaderBar>
            <Grid className={classes.shopping_cart_container}>

                {(() => {
                    if (payment_status === 'SUCCESS') {
                        return (
                            <>
                                <Grid container direction="column" alignContent="center" justifyContent="center">
                                        <Avatar src={payment_successful} variant="square" className={classes.payment_successful_icon} />
                                        <Box className={classes.payment_successful_content}>
                                            <Typography variant="h5" className={classes.payment_status_title}>Paid</Typography>
                                            <Typography variant="h5" className={classes.payment_status_sub_title}>Thank you!<br /><br/>
                                                Your payment for this order has been successfully received.
                                            </Typography>
                                        </Box>
                                </Grid>

                                <Grid container alignContent="center" justifyContent="center">
                                    <Button variant="contained" className={classes.btn_primary}
                                            onClick={() => goBack()}>
                                        <Typography>Continue shopping</Typography>
                                    </Button>

                                </Grid>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <Grid container direction="column" alignContent="center" justifyContent="center">
                                        <Avatar src={payment_failed} variant="square" className={classes.payment_successful_icon} />

                                        <Box className={classes.payment_successful_content}>
                                            <Typography variant="h5" className={classes.payment_status_title}>Technical error</Typography>
                                            <Typography variant="h5" className={classes.payment_status_sub_title}>You can go back and try again.<br /><br/>
                                                For help, please <Link to="/contact_us">contact us.</Link>
                                            </Typography>
                                        </Box>
                                </Grid>

                                <Grid container alignContent="center" justifyContent="center">
                                    <Button variant="contained" className={classes.btn_primary_failed}
                                            onClick={() => goBack()}>
                                        <Typography>Go back</Typography>
                                    </Button>

                                </Grid>
                            </>
                        )
                    }
                })()}

                {snackbarLoader()}
            </Grid>
            </Paper>
        </ThemeProvider>
    );
}

export default withStyles(styles)(CyberSourcePaymentStatus);
