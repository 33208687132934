import runtimeEnv from "@mars/heroku-js-runtime-env";
import * as Cookies from "js-cookie";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { login, session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreSet } from "../utils/app_storage";
import { decrypt } from "../utils/crypto_encryption";

export default function ProtectedRoute(props) {
    const Component = props.component
    const env = runtimeEnv()
    const dispatch = useDispatch()
    const signed = useSelector(state => state.auth.signed)

    const validateToken = () => {
        let token = Cookies.get("AbsaAgiza")

        if (!token) {
            dispatch(session_expired())
            return <Redirect to="/login"/>
        }

        if (signed) {
            return
        }

        fetch(env.REACT_APP_SERVER_API_URL + "/retailers/validate_token",
            {
                method: "POST",
                headers: {...authorization_headers(), ...{Authorization: token}},
            })
            .then(res => {
                res.text().then(text => {
                    if (res.ok) {
                        let data = JSON.parse(decrypt(text))
                        if (data.valid === true) {
                            setCookie(data.token)
                            let retailer_data = data.retailer
                            appStoreSet("retailer_data", JSON.stringify(retailer_data))
                            dispatch(login(retailer_data))

                        } else {
                            dispatch(session_expired())
                        }
                    } else {
                        dispatch(session_expired())
                    }
                }).catch(() => {
                    dispatch(session_expired())
                })
            })
            .catch(() => {
                dispatch(session_expired())
            })
    }

    const setCookie = (token) => {
        // Cookie should exipire in 5 minutes
        var expite_at = new Date(new Date().getTime() + 5 * 60 * 1000)
        Cookies.remove('AbsaAgiza')
        Cookies.set('AbsaAgiza', token, {expires: expite_at, secure: true})
    }

    validateToken()

    if (!signed) {
        return <Redirect to="/login"/>
    }

    return <Component/>
}