import React, { useState } from 'react';
import { Autocomplete } from '@material-ui/lab'; // For Material-UI v4
import { TextField } from '@material-ui/core'; // For Material-UI v4

const BranchSelector = ({ name, onBranchSelect }) => {
    const [selectedBranch, setSelectedBranch] = useState(null);

  // Define the branches array inside the child component
  const branches = [
    { code: 2, name: 'Kapsabet Branch' },
    { code: 3, name: 'Eldoret Branch' },
    { code: 4, name: 'Embu Branch' },
    { code: 5, name: 'Murang A Branch' },
    { code: 6, name: 'Kapenguria Branch' },
    { code: 7, name: 'Kericho Branch' },
    { code: 8, name: 'Kisii Branch' },
    { code: 9, name: 'Kisumu Branch' },
    { code: 10, name: 'Parkside Branch' },
    { code: 11, name: 'Limuru Branch' },
    { code: 12, name: 'Malindi Branch' },
    { code: 13, name: 'Meru Branch' },
    { code: 14, name: 'Eastleigh Branch' },
    { code: 15, name: 'Kitui Branch' },
    { code: 16, name: 'Nkrumah Road Branch' },
    { code: 17, name: 'Garissa Branch' },
    { code: 19, name: 'Kilifi Branch' },
    { code: 23, name: 'Gilgil Express Branch' },
    { code: 24, name: 'Thika Road Mall' },
    { code: 26, name: 'Kakamega Branch' },
    { code: 27, name: 'Nakuru East' },
    { code: 28, name: 'Buruburu' },
    { code: 29, name: 'Bomet' },
    { code: 30, name: 'Nyeri Branch' },
    { code: 31, name: 'Thika Branch' },
    { code: 33, name: 'Gikomba' },
    { code: 35, name: 'Mbale' },
    { code: 37, name: 'River Road Branch' },
    { code: 40, name: 'Machakos Branch' },
    { code: 41, name: 'Narok Express Branch' },
    { code: 42, name: 'Isiolo Branch' },
    { code: 43, name: 'Ngong Branch' },
    { code: 44, name: 'Maua Branch' },
    { code: 45, name: 'Hurlingham Branch' },
    { code: 48, name: 'Bungoma' },
    { code: 49, name: 'Lavington Branch' },
    { code: 51, name: 'Homa Bay Branch' },
    { code: 52, name: 'Ongata Rongai Express Branch' },
    { code: 54, name: 'Voi Branch' },
    { code: 55, name: 'Muthaiga Branch' },
    { code: 57, name: 'Githunguri Branch' },
    { code: 58, name: 'Webuye Branch' },
    { code: 61, name: 'Nakumatt-Westgate Branch' },
    { code: 62, name: 'Kabarnet Branch' },
    { code: 63, name: 'Kerugoya Branch' },
    { code: 64, name: 'Taveta Branch' },
    { code: 65, name: 'Karen Branch' },
    { code: 67, name: 'Ruaraka Branch' },
    { code: 68, name: 'Kitengela Branch' },
    { code: 69, name: 'Wote Branch' },
    { code: 70, name: 'Lunga Lunga Branch' },
    { code: 72, name: 'Juja Branch' },
    { code: 74, name: 'Kikuyu Branch' },
    { code: 76, name: 'Nyali Branch' },
    { code: 77, name: 'Absa Towers Branch' },
    { code: 79, name: 'Avon Centre Branch' },
    { code: 80, name: 'Migori Branch' },
    { code: 81, name: 'Digo Branch' },
    { code: 83, name: 'Nairobi University Express Branch' },
    { code: 84, name: 'Bunyala Road Branch' },
    { code: 86, name: 'Nairobi West Branch' },
    { code: 88, name: 'Busia' },
    { code: 90, name: 'Abc Premier Life Centre' },
    { code: 93, name: 'Kariobangi Branch' },
    { code: 94, name: 'Queensway House Branch' },
    { code: 100, name: 'Diani Branch' },
    { code: 103, name: 'Nairobi J.K.I.A Branch' },
    { code: 105, name: 'Village Market Premier Life Centre' },
    { code: 106, name: 'Sarit Branch' },
    { code: 109, name: 'Yaya Centre- Premier Life Centre' },
    { code: 111, name: 'Naivasha Branch' },
    { code: 113, name: 'Market Branch' },
    { code: 114, name: 'Changamwe Branch' },
    { code: 125, name: 'Nakuru West Branch' },
    { code: 130, name: 'Harambee Ave-Premier Life Centre' },
    { code: 132, name: 'Kitale Branch' },
    { code: 139, name: 'Nyahururu Branch' },
    { code: 140, name: 'Two Rivers Branch' },
    { code: 145, name: 'Moi Avnue Mombasa Premier Life Cntr' },
    { code: 149, name: 'Eastleigh Bbs Mall Branch' },
    { code: 150, name: 'Absa Rupa Mall Branch' },
    { code: 152, name: 'Absa Imaara Mall Branch' },
    { code: 154, name: 'Kamakis Branch' },
    { code: 190, name: 'Nanyuki Branch' },
    { code: 206, name: 'Karatina Branch' },
  ];

  const handleChange = (event, value) => {
    setSelectedBranch(value);

    // Create an event-like object with a target property
    const eventLike = {
      target: {
        name: name, // Use the `name` prop passed to BranchSelector
        value: value, // Pass the selected branch object
      },
    };

    onBranchSelect(eventLike); // Pass the event-like object to the parent
  };

  return (
    <Autocomplete
      options={branches}
      getOptionLabel={(option) => option.name} // Display branch name in the dropdown
      getOptionSelected={(option, value) => option.code === value.code} // Compare by branch code
      value={selectedBranch}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name} // Pass the name prop
          label="Select Branch"
          variant="outlined"
          placeholder="Search branch..."
        />
      )}
    />
  );
};

export default BranchSelector;