import runtimeEnv from "@mars/heroku-js-runtime-env";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Snackbar from "@material-ui/core/Snackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { grey } from "@material-ui/core/colors";
import {
    ThemeProvider,
    createMuiTheme,
    withStyles,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import RemoveIcon from "@material-ui/icons/Remove";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet, appStoreRemove, appStoreSet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";

const env = runtimeEnv();
const transparent_logo_path =
  env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = env.REACT_APP_PRIMARY_COLOR || "#640032";

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
    white: {
      main: grey[50],
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});
const styles = (theme) => ({
  ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  cat_extended_btn: {
    width: "110px",
    height: "30px",
  },
  outlined_clear_button: {
    width: "40%",
    float: "left",
    fontSize: "18px",
  },
  btn_checkout: {
    width: "40%",
    float: "right",
    backgroundColor: primary_main_color,
    color: "white",
    fontSize: "18px",
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
    left: "auto",
    right: "auto",
    position: "fixed",
  },
  table: {
    width: "100%",
  },
  table_cell: {
    border: 0,
    fontSize: "18px",
  },
  list: {
    marginTop: "60px",
    marginBottom: "60px",
    borderRadius: "0px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
  no_decoration: {
    textDecoration: "none",
  },
});

function ShoppingCart(props) {
  const history = useHistory();
  const { classes } = props;
  const dispatch = useDispatch();
  let cached_shopping_cart = [];

  if (appStoreGet("shopping_cart") !== null) {
    cached_shopping_cart = JSON.parse(appStoreGet("shopping_cart"));
  }

  const [shopping_cart, setShoppingCart] = useState(cached_shopping_cart);
  const [loading, setLoading] = useState(false);
  const [disable_submit_btn, setDisableSubmitBtn] = useState(false);
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);

  appStoreRemove("approved_order_id");

  const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const removeFromCart = (product_id) => {
    let updated_cart = shopping_cart.slice();
    let selected_product = updated_cart.filter(function (item) {
      return item.id === product_id;
    })[0];

    if (selected_product !== undefined) {
      let index = updated_cart.findIndex(
        (product) => product.id === product_id
      );

      if (selected_product.quantity === 0 || selected_product.quantity === 1) {
        updated_cart.splice(index, 1);
      } else {
        updated_cart[index].quantity--;
      }

      appStoreSet("shopping_cart", JSON.stringify(updated_cart));
      setShoppingCart(updated_cart);
    }
  };

  const addToCart = (product_id) => {
    let selected_product = shopping_cart.filter(function (item) {
      return item.id === product_id;
    })[0];

    if (selected_product !== undefined) {
      let existing_product = shopping_cart.filter(function (item) {
        return item.id === product_id;
      })[0];

      let updated_cart = shopping_cart.slice();

      if (existing_product !== undefined) {
        let index = updated_cart.findIndex(
          (product) => product.id === product_id
        );

        if (index !== -1) {
          updated_cart[index].quantity++;
        }
      } else {
        selected_product.quantity = 1;
        updated_cart.push(selected_product);
      }

      appStoreSet("shopping_cart", JSON.stringify(updated_cart));
      setShoppingCart(updated_cart);
    }
  };

  const cartQuantity = () => {
    let sum = 0;
    shopping_cart.forEach(function (item) {
      sum += item.quantity;
    });

    return sum;
  };

  const cartTotal = () => {
    let total = 0;
    shopping_cart.forEach(function (item) {
      total += item.quantity * parseInt(item.price);
    });

    return total;
  };

  const cartProductQualtity = (product_id) => {
    let selected_product = shopping_cart.filter(function (item) {
      return item.id === product_id;
    })[0];

    if (selected_product !== undefined) {
      return selected_product.quantity;
    }
    return 0;
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const backToOrderingPage = () => {
    history.push("/ordering");
  };

  const clearCart = () => {
    let updated_cart = shopping_cart.slice();
    updated_cart.splice(0, updated_cart.length);
    appStoreSet("shopping_cart", JSON.stringify(updated_cart));
    setShoppingCart(updated_cart);
    backToOrderingPage();
  };

  const checkout = () => {
    setDisableSubmitBtn(true);
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      return;
    }

    let order_data = JSON.stringify({ line_items: shopping_cart });

    setLoading(true);
    fetch(env.REACT_APP_SERVER_API_URL + "/orders", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(order_data),
    })
      .then((res) => res.text())
      .then((res) => {
        let order = JSON.parse(decrypt(res));

        // Check if the distributor requires approval process
        const is_ordering_approval_required = order.data.distributor.is_ordering_approval_required

        if (is_ordering_approval_required) {
          setSnackbarMessage("Order successfully submitted for approval.");
        } else {
          setSnackbarMessage("Order successfully created.");
        }

        setSnackbarSeverity("success");
        openSnackbar(true);
        setLoading(false);

        sleep(2000).then(() => {
          history.push("/pending_orders");
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/ordering" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography className={classes.input} align="center">
                <b>My Basket</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>
          <List className={classes.list}>
            {shopping_cart.map((product, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={product.name}
                  secondary={
                    <Typography>
                      <b>{toCurrency(product.price)}</b>
                    </Typography>
                  }
                />
                <ButtonGroup
                  color="primary"
                  size="small"
                  className={classes.group_buttons}
                >
                  <Fab
                    onClick={() => removeFromCart(product.id)}
                    style={{
                      borderRadius: "17px 0 0 17px",
                      backgroundColor: primary_main_color,
                      fontSize: "18px",
                      height: "unset",
                    }}
                  >
                    <RemoveIcon />
                  </Fab>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: primary_main_color,
                      fontSize: "18px",
                      height: "unset",
                    }}
                  >
                    {cartProductQualtity(product.id)}
                  </Button>
                  <Fab
                    onClick={() => addToCart(product.id)}
                    style={{
                      borderRadius: "0 17px 17px 0",
                      backgroundColor: primary_main_color,
                      fontSize: "18px",
                      height: "unset",
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </ButtonGroup>
              </ListItem>
            ))}
          </List>

          <AppBar
            className={`${classes.stickToBottom} ${classes.contained_width}`}
            color="white"
          >
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.table_cell}>
                      Number of products:
                    </TableCell>
                    <TableCell align="right" className={classes.table_cell}>
                      {cartQuantity()}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.table_cell}>
                      Delivery fee:
                    </TableCell>
                    <TableCell align="right" className={classes.table_cell}>
                      {toCurrency(0)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.table_cell}>
                      <b>Total amount:</b>
                    </TableCell>
                    <TableCell align="right" className={classes.table_cell}>
                      <b>{toCurrency(cartTotal())}</b>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <div className={classes.root}>
                <Button
                  variant="outlined"
                  className={`${classes.outlined_button} ${classes.outlined_clear_button}`}
                  onClick={() => clearCart()}
                >
                  CLEAR BASKET
                </Button>
                <Button
                  variant="contained"
                  className={classes.btn_checkout}
                  onClick={() => checkout()}
                  disabled={disable_submit_btn}
                >
                  SUBMIT ORDER
                </Button>
              </div>
            </TableContainer>
          </AppBar>

          {snackbarLoader()}
          {progressLoader()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(ShoppingCart);
