import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Snackbar from "@material-ui/core/Snackbar";
import Switch from "@material-ui/core/Switch";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { grey, red } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from '../common/commonStyles';
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreSet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";

const env = runtimeEnv();

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
    ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  root: {
    flexGrow: 1,
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  title_input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "18px",
    color: "white",
  },
  primary_button: {
    background: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
    fontSize: "18px",
  },
  orders_date: {
    color: grey[700],
    float: "right",
  },
  fields_toolbar: {
    borderColor: "#737373",
    border: "2px solid",
    borderRadius: "5px",
    paddingLeft: "8px",
  },
  success_btn: {
    backgroundColor: "#3BB719",
    boxShadow: "none",
    color: grey[50],
    borderRadius: "20px",
    fontSize: "18px",
    textTransform: "none",
  },
  arrears_btn: {
    backgroundColor: red[500],
    boxShadow: "none",
    color: grey[50],
    borderRadius: "20px",
    fontSize: "18px",
    textTransform: "none",
  },
  top_headers: {
    fontWeight: 300,
    fontSize: "large",
  },
  no_decoration: {
    textDecoration: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

function LoanDetails(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const loan = useSelector((state) => state.loans.loan);
  const [repayment_amount, setRepaymentAmount] = useState(null);
  const [payoff_loan, setPayoffLoan] = useState(false);
  const retailer = retailer_data;

  const [loading, setLoading] = useState(false);
  const [open_snackbar, openSnackbar] = useState(false);
  const [snackbar_severity, setSnackbarSeverity] = useState("warning");
  const [snackbar_message, setSnackbarMessage] = useState(null);
  const [mode_of_repayment, setModeOfRepayment] = useState("mpesa");

  if (
    ["Active", "Arrears", "Written_off"].includes(loan.loan_status) !== true
  ) {
    history.goBack();
  }

  let mode_of_repayments;

  if (env.REACT_APP_CREDIT_CARD === "true") {
    mode_of_repayments = [
      {
        value: "mpesa",
        label: "Mpesa",
      },
      {
        value: "credit_card",
        label: "Card",
      },
      {
        value: "bank_transfer",
        label: "Bank Transfer",
      },
    ];
  } else {
    mode_of_repayments = [
      {
        value: "mpesa",
        label: "Mpesa",
      },
      {
        value: "bank_transfer",
        label: "Bank Transfer",
      },
    ];
  }

  const changeModeOfPayment = (event) => {
    let value = event.target.value;
    setModeOfRepayment(value);
    appStoreSet("mode_of_repayment", value);
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const repayLoan = () => {
    if (mode_of_repayment === "mpesa") {
      let token = Cookies.get("AbsaAgiza");

      if (!token) {
        dispatch(session_expired());
        return;
      }

      let amount = parseFloat(
        repayment_amount.toString().replace(",", "")
      ).toFixed(2);
      amount = parseFloat(amount);
      let balance = parseFloat(loan.balance).toFixed(2);

      if (amount <= 0) {
        setSnackbarMessage("Enter amount above 0");
        openSnackbar(true);
        return;
      } else if (amount > balance && amount - balance > 1) {
        let loan = retailer_data.is_la_riba === true ? 'facility' : 'loan'
        setSnackbarMessage("The amount entered is more than the "+loan+" balance");
        openSnackbar(true);
        return;
      }

      setLoading(true);

      fetch(env.REACT_APP_SERVER_API_URL + "/loan_repayments", {
        method: "POST",
        headers: { ...authorization_headers(), ...{ Authorization: token } },
        body: encrypt(JSON.stringify({ loan_ids: [loan.id], amount: amount })),
      })
        .then((res) => {
          res
            .text()
            .then((text) => {
              let data = JSON.parse(decrypt(text));
              appStoreSet("loan_repayments", JSON.stringify(data));
              history.push("/confirm_repayment");
              setLoading(false);
            })
            .catch(() => {
              let loan = retailer_data.is_la_riba === true ? 'facility' : 'loan'
              setSnackbarMessage(
                "Error repay "+loan+". Please retry or contact support."
              );
              openSnackbar(true);
              setLoading(false);
            });
        })
        .catch(() => {
          let loan = retailer_data.is_la_riba === true ? 'facility' : 'loan'
          setSnackbarMessage(
            "Error repay "+loan+". Please retry or contact support."
          );
          openSnackbar(true);
          setLoading(false);
        });
    } else if (mode_of_repayment === "credit_card") {
      appStoreSet("credit_card_mode", "repayment");
      appStoreSet("repayment_amount", repayment_amount);
      history.push("/initiate_payment");
    } else if (mode_of_repayment === "bank_transfer") {
      let token = Cookies.get("AbsaAgiza");

      if (!token) {
        dispatch(session_expired());
        return;
      }

      let amount = parseFloat(
        repayment_amount.toString().replace(",", "")
      ).toFixed(2);
      amount = parseFloat(amount);
      let balance = parseFloat(loan.balance).toFixed(2);

      if (amount <= 0) {
        setSnackbarMessage("Enter amount above 0");
        openSnackbar(true);
        return;
      } else if (amount > balance && amount - balance > 1) {
        setSnackbarMessage("The amount entered is more than the loan balance");
        openSnackbar(true);
        return;
      }

      setLoading(true);

      fetch(env.REACT_APP_SERVER_API_URL + "/absa_transfer/initiate_transfer", {
        method: "POST",
        headers: { ...authorization_headers(), ...{ Authorization: token } },
        body: encrypt(JSON.stringify({ loan_id: loan.id, amount: amount, type: "repayment" })),
      })
      .then(response => response.json())
      .then(res => {
        window.location.replace(res['url']);
        setLoading(false);
      })
      .catch(() => {
        setSnackbarMessage(
          "Error repay loan. Please retry or contact support."
        );
        openSnackbar(true);
        setLoading(false);
      });
    }
  };

  const updateRepaymentAmount = (event) => {
    let { value } = event.target;
    setRepaymentAmount(value);
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const updatePayoffLoan = (event) => {
    let checked = event.target.checked;
    let loan_balance = parseFloat(loan.balance).toFixed(2);
    let excess_amount = (Math.ceil(loan_balance) - loan_balance).toFixed(2);
    setPayoffLoan(checked);

    if (checked) {
      setRepaymentAmount(Math.ceil(loan_balance));

      if (Math.ceil(loan_balance) > loan_balance) {
        setSnackbarSeverity("info");
        let loan = retailer_data.is_la_riba === true ? 'facility' : 'loan'
        setSnackbarMessage(
          "You are about to overpay your "+loan+" with KES " +
            excess_amount +
            ". The excess amount will be Credited into your Absa Wezesha Stock Account"
        );
        openSnackbar(true);
      }
    } else {
      setRepaymentAmount("");
    }
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const loanStatus = () => {
    if (["Active"].includes(loan.loan_status)) {
      return (
        <Grid container justify="center">
          <Button
            variant="contained"
            className={classes.success_btn}
            disableElevation={true}
          >
            {loan.loan_status}
          </Button>
        </Grid>
      );
    } else if (["Arrears", "Written_off"].includes(loan.loan_status)) {
      return (
        <Grid container justify="center">
          <Button
            variant="contained"
            className={classes.arrears_btn}
            disableElevation={true}
          >
            Arrears
          </Button>
        </Grid>
      );
    }
  };

  const radio_label = (option) => {
    let label = option.label
    if (option.value === 'bank_transfer' && !retailer.can_do_bank_transfer) {
      label = option.label + ' (Missing bank details)'
    }
    return label
  }

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/loan_details" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography className={classes.title_input} align="center">
              <b>{retailer_data.is_la_riba === true ? 'Facility ' : 'Loan '} No. {loan.loan_id}</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>
          <br />
          <br />
          {loanStatus()}
          <br />

          <Container className={classes.page_container}>
            <Grid container justify="center">
              <Typography className={classes.top_headers} gutterBottom>
                Due balance
              </Typography>
            </Grid>
            <Grid container justify="center">
              <Typography variant="h4" gutterBottom>
                {toCurrency(loan.balance)}
              </Typography>
            </Grid>
          </Container>

          <br />

          <Container fullWidth>
            <Toolbar className={classes.fields_toolbar}>
              <InputBase
                className={classes.input}
                placeholder="Enter repayment amount"
                value={repayment_amount}
                onChange={updateRepaymentAmount}
              />
            </Toolbar>
          </Container>

          <br />
          <Grid container justify="center">
            <FormControlLabel
              control={
                <Switch
                  checked={payoff_loan}
                  onChange={updatePayoffLoan}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Repay full amount"
            />
          </Grid>

          <br />
          <br />
          <Container>
            <FormControl component="fieldset">
              <FormLabel component="legend">Mode of repayment</FormLabel>
              <RadioGroup
                colorPrimary="primary"
                value={mode_of_repayment}
                onChange={changeModeOfPayment}
              >
                {mode_of_repayments.map((option) => (
                  <FormControlLabel
                    value={option.value}
                    control={<Radio />}
                    label={ radio_label(option)}
                    disabled={option.value === 'bank_transfer' && !retailer.can_do_bank_transfer }
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <Button
              variant="contained"
              className={classes.primary_button}
              onClick={() => repayLoan()}
              fullWidth
            >
              Repay
            </Button>
          </Container>

          {snackbarLoader()}
          {progressLoader()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(LoanDetails);
