import runtimeEnv from "@mars/heroku-js-runtime-env";
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import MuiAlert from "@material-ui/lab/Alert";
import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { businessTypes, counties } from "../lib/data";
import { authorization_headers } from "../utils/api_authorization";
import { decrypt, encrypt } from "../utils/crypto_encryption";

const env = runtimeEnv();
const bg_image_path = "/assets/images/bg_image.jpg";
const logo_path = env.REACT_APP_LOGO || "/assets/images/logo.png";
const organization_name = env.REACT_APP_ORGANISATION_NAME || "Wezesha Stock";
const primary_main_color = env.REACT_APP_PRIMARY_COLOR || "#640032";
const primary_links_color = env.REACT_APP_PRIMARY_COLOR || "#F0325A";
const payment_successful = "/assets/images/payment_successful.png";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

const styles = (theme) => ({
  // ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  welcome_message: {
    color: "white",
    fontSize: "34px",
    fontWeight: "900",
  },
  welcome_message_sub: {
    color: "white",
    fontSize: "20px",
  },
  logo: {
    height: "100px",
    width: "100px",
    backgroundRepeat: "no-repeat",
    marginTop: "160px",
    backgroundImage: `url(${env.REACT_APP_LOGO})`,
  },
  page_container: {
    marginTop: "20px",
    paddingTop: "40px",
    backgroundColor: "white",
    borderRadius: "8px",
  },
  fields_toolbar: {
    borderColor: "#C9C9C9",
    border: "1px solid",
    borderRadius: "4px",
    paddingLeft: "8px",
    height: "40px !important",
    width: "100%",
    marginBottom: "10px",
  },
  iconButton: {
    padding: 10,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
    },
  },
  input_label: {
    marginBottom: "2px",
  },
  primary_button: {
    background: env.REACT_APP_PRIMARY_COLOR,
    color: "white",
    width: "100%",
  },
  primary_links: {
    color: env.REACT_APP_PRIMARY_COLOR,
    textDecoration: "none",
  },
  disclaimer_msg: {
    textAlign: "center",
    fontSize: "0.75rem",
  },
  disclaimer_checkbox_msg: {
    paddingTop: "5px",
    textAlign: "center",
  },
  disclaimer_checkbox: {
    marginTop: "-5px",
  },
  no_decoration: {
    textDecoration: "none",
    color: "inherit !important",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
  payment_successful_icon: {
    // position: "absolute",
    // top: "254px",
    left: "0px",
    right: "0px",
    width: "90px",
    height: "90px",
    margin: "auto",
  },
  payment_successful_content: {
    // position: "absolute",
    top: "340px",
    left: "0px",
    right: "0px",
    width: "320px",
    margin: "auto",
  },
  payment_status_title: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "400",
    paddingTop: "15px",
  },

  payment_status_sub_title: {
    textAlign: "center",
    fontSize: "16px",
    color: "#565656",
    paddingTop: "32px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginBottom: "40px",
    "& a": {
      color: "#F0397B",
      textDecoration: "none",
    },
  },

  payment_status_text: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: "12px",
    color: "#565656",
    paddingTop: "2px",
  },
  paperBackground: {
    minHeight: "var(--doc-height)",
    background: "rgb(192,69,98)",
    background:
      "-moz-linear-gradient(0deg, rgba(192,69,98,1) 0%, rgba(87,32,78,1) 100%)",
    background:
      "-webkit-linear-gradient(0deg, rgba(192,69,98,1) 0%, rgba(87,32,78,1) 100%)",
    background:
      "linear-gradient(0deg, rgba(192,69,98,1) 0%, rgba(87,32,78,1) 100%)",
    filter:
      'progid:DXImageTransform.Microsoft.gradient(startColorstr="#c04562",endColorstr="#57204e",GradientType=1)',
  },
});

function SignUp(props) {
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState(new Map());
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [open_snackbar, openSnackbar] = React.useState(false);
  const [snackbar_severity, setSnackbarSeverity] = React.useState("warning");
  const [snackbar_message, setSnackbarMessage] = React.useState(null);

  const upsert = (key, value) => {
    setState((prev) => new Map(prev).set(key, value));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let input_value = value;

    if (
      name === "bank_with_absa" ||
      name === "consent_to_contact_me" ||
      name === "consent_to_getting_history" ||
      name === "have_customer_number"
    ) {
      input_value = event.target.checked;
    }

    upsert(name, input_value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let params = {
      retailerName: state.get("retailerName"),
      businessName: state.get("businessName"),
      contact: state.get("contact"),
      email: state.get("email"),
      businessType: state.get("businessType"),
      products: state.get("products"),
      county: state.get("county"),
      town: state.get("town"),
      // area: state.get("area"),
      bank_with_absa: state.get("bank_with_absa") || false,
      consent_to_getting_history:
        state.get("consent_to_getting_history") || false,
      consent_to_contact_me: state.get("consent_to_contact_me") || false,
      have_customer_number: state.get("have_customer_number") || false,
      number_of_retailers: state.get("number_of_retailers") || 0,
      number_of_distributors: state.get("number_of_distributors") || 0,
      suppliers: state.get("suppliers") || "",
      refer_stream_sales_code: state.get("refer_stream_sales_code") || "",
      national_id_number: state.get("national_id_number") || "",
      customer_number: state.get("customer_number") || "",
    };

    setLoading(true);
    fetch(env.REACT_APP_SERVER_API_URL + "/retailers/signup", {
      method: "POST",
      headers: authorization_headers(),
      body: encrypt(JSON.stringify(params)),
    })
      .then((res) => {
        res
          .text()
          .then((text) => {
            if (res.ok) {
              setLoading(false);
              setSubmitted(true);
              // openSnackbar(true);
              // setSnackbarMessage(
              //   "Thank you for signing up to Wezesha Stock Finance. Absa Bank is reviewing your information and will contact you within 48 hours. Terms and Conditions Apply."
              // );
              // setSnackbarSeverity("success");
              // history.push("/login");
            } else if (res.status === 422) {
              let data = JSON.parse(decrypt(text));
              let errorMessages = data.errors;
              setLoading(false);
              openSnackbar(true);
              setSnackbarMessage("The details entered are not valid: " + errorMessages.join(", "));
            } else {
              setLoading(false);
              openSnackbar(true);
              setSnackbarMessage("The details entered are not valid.");
            }
          })
          .catch(() => {
            setLoading(false);
            openSnackbar(true);
            setSnackbarMessage("Error submitting information");
          });
      })
      .catch(() => {
        setLoading(false);
        openSnackbar(true);
        setSnackbarMessage("Error submitting information");
      });
  };

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    openSnackbar(false);
    setSnackbarSeverity("warning");
  };

  const snackbarLoader = () => {
    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className={classes.snackbar}
        open={open_snackbar}
      >
        <Alert
          onClose={() => closeSnackbar()}
          severity={snackbar_severity}
          className={classes.alert_override}
        >
          {snackbar_message}
        </Alert>
      </Snackbar>
    );
  };

  const progressLoader = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const footer_message = () => {
    if (organization_name === "Wezesha Stock") {
      return "Absa Bank Kenya PLC is regulated by the Central Bank of Kenya.";
    } else {
      return "";
    }
  };

  const goBack = () => {
    setSubmitted(false);
    setState(new Map());
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.paperBackground}
          style={{
            // background: `url(${env.REACT_APP_BG_IMAGE})`,
            // backgroundSize: "cover",
            height: "100vh",
          }}
        >
          <Grid
            position="fixed"
            style={{ boxShadow: "none", backgroundColor: "white" }}
          >
            <Toolbar>
              <Avatar
                src={env.REACT_APP_LOGO}
                variant="square"
                className={classes.square}
              />
            </Toolbar>
          </Grid>
          <Grid
            container
            justify="center"
            style={{
              padding: "0",
              maxHeight: `calc(100vh - 64px)`,
              overflow: "auto",
            }}
          >
            <Grid
              container
              style={{ width: "95%", maxWidth: "400px", marginTop: "10px" }}
            >
              {!submitted && (
                <Grid container className={classes.root}>
                  <Grid xs={12}>
                    <Grid container justify="center">
                      <Grid />
                      <Grid>
                        <Typography
                          variant="h6"
                          className={classes.welcome_message}
                          style={{ textAlign: "center", marginTop: "10px", lineHeight: "38px" }}
                        >
                          Register for {env.REACT_APP_ORGANISATION_NAME}
                        </Typography>
                        <Typography
                          className={classes.welcome_message_sub}
                          style={{ textAlign: "center", lineHeight: "22px", marginTop: "10px" }}
                        >
                          Get instant order financing up to KES 10 Million. Register below for early access
                        </Typography>
                      </Grid>
                      <Grid />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Container className={classes.page_container} fullWidth>
                {submitted ? (
                  <>
                    <Grid
                      container
                      direction="column"
                      alignContent="center"
                      justifyContent="center"
                      marginBottom="20px"
                    >
                      <Avatar
                        src={payment_successful}
                        variant="square"
                        className={classes.payment_successful_icon}
                      />
                      <Box className={classes.payment_successful_content}>
                        <Typography
                          variant="h5"
                          className={classes.payment_status_title}
                        >
                          Registration successful
                        </Typography>
                        <Typography
                          variant="h5"
                          className={classes.payment_status_sub_title}
                        >
                          Thank you for registering for Wezesha Stock financing.
                          Absa is reviewing your information and will contact
                          you within 48 hours.
                          <br />
                          <br />
                          <a
                            href="https://wezesha-business.agiza.io/Absa%20Wezesha%20Stock%20T&Cs%20V1.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Terms and Conditions
                          </a>{" "}
                          apply.
                        </Typography>
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <>
                    <form onSubmit={handleSubmit}>
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        First & last name*
                      </Typography>
                      <InputBase
                        className={classes.fields_toolbar}
                        placeholder="Enter your first & last name"
                        name="retailerName"
                        value={state.get("retailerName")}
                        onChange={handleChange}
                        required
                      />
                      <br />
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        Business name*
                      </Typography>
                      <InputBase
                        className={classes.fields_toolbar}
                        placeholder="Enter your business name"
                        name="businessName"
                        value={state.get("businessName")}
                        onChange={handleChange}
                        required
                      />

                      <br />
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        National ID Number
                      </Typography>
                      <InputBase
                        className={classes.fields_toolbar}
                        placeholder="National ID Number"
                        name="national_id_number"
                        value={state.get("national_id_number")}
                        onChange={handleChange}
                        required
                      />

                      <Grid container>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.disclaimer_msg}
                        >
                          <FormControlLabel
                            className={classes.disclaimer_checkbox}
                            control={
                              <Checkbox
                                name="have_customer_number"
                                checked={state.get("have_customer_number")}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon color="primary" />}
                                onChange={handleChange}
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                gutterBottom
                                className={classes.disclaimer_checkbox_msg}
                              >
                                I have a customer number
                              </Typography>
                            }
                          />
                        </Typography>
                      </Grid>

                      { state.get("have_customer_number") && (
                        <>
                          <Typography
                            variant="body1"
                            className={classes.input_label}
                          >
                            Customer Number
                          </Typography>
                          <InputBase
                            className={classes.fields_toolbar}
                            placeholder="Customer Number"
                            name="customer_number"
                            value={state.get("customer_number")}
                            onChange={handleChange}
                            required={state.get("have_customer_number")}
                          />
                          <br />
                        </>
                      )}

                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        Mobile number*
                      </Typography>
                      <InputBase
                        className={classes.fields_toolbar}
                        placeholder="Enter your mobile number"
                        name="contact"
                        value={state.get("contact")}
                        onChange={handleChange}
                        required
                      />

                      <br />
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        Email*
                      </Typography>
                      <InputBase
                        className={classes.fields_toolbar}
                        placeholder="Enter your email address"
                        name="email"
                        value={state.get("email")}
                        onChange={handleChange}
                        required
                      />

                      <br />
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        County*
                      </Typography>
                      <Select
                        className={classes.fields_toolbar}
                        prompt="Enter your type of business"
                        name="county"
                        value={state.get("county")}
                        onChange={handleChange}
                        required
                      >
                        {counties.map((type) => (
                          <MenuItem key={type.code} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <br />
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        Town*
                      </Typography>
                      <InputBase
                        className={classes.fields_toolbar}
                        placeholder="Enter your town"
                        name="town"
                        value={state.get("town")}
                        onChange={handleChange}
                        required
                      />

                      {/* <br />
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        General area*
                      </Typography>
                      <InputBase
                        className={classes.fields_toolbar}
                        placeholder="Enter your general area"
                        name="area"
                        value={state.get("area")}
                        onChange={handleChange}
                        required
                      /> */}

                      <br />
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        Type of business*
                      </Typography>
                      <Select
                        className={classes.fields_toolbar}
                        placeholder="Enter your type of business"
                        name="businessType"
                        value={state.get("businessType")}
                        onChange={handleChange}
                        required
                      >
                        {businessTypes.map((type) => (
                          <MenuItem key={type.name} value={type.name}>
                            {type.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <br />
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        Main suppliers*
                      </Typography>
                      <InputBase
                        className={classes.fields_toolbar}
                        placeholder="Enter main suppliers separated by commas"
                        name="suppliers"
                        value={state.get("suppliers")}
                        onChange={handleChange}
                        required
                      />

                      {/* {(state.get("businessType") === "Retailer" ||
                        state.get("businessType") === "Stockist" ||
                        state.get("businessType") === "Distributor" ||
                        state.get("businessType") === "Wholesaler") && (
                        <>
                          <br />
                          <Typography
                            variant="body1"
                            className={classes.input_label}
                          >
                            Major Suppliers*
                          </Typography>
                          <InputBase
                            className={classes.fields_toolbar}
                            placeholder="Enter suppliers separated by commas"
                            name="suppliers"
                            value={state.get("suppliers")}
                            onChange={handleChange}
                            required
                          />
                        </>
                      )} */}

                      {(state.get("businessType") === "Distributor" ||
                        state.get("businessType") === "Manufacturer") && (
                        <>
                          <br />
                          <Typography
                            variant="body1"
                            className={classes.input_label}
                          >
                            Number of Retailers / Stockists*
                          </Typography>
                          <InputBase
                            className={classes.fields_toolbar}
                            placeholder="Enter number of retailers and Stockists"
                            name="number_of_retailers"
                            value={state.get("number_of_retailers")}
                            type="number"
                            onChange={handleChange}
                            required
                          />
                        </>
                      )}

                      {state.get("businessType") === "Manufacturer" && (
                        <>
                          <br />
                          <Typography
                            variant="body1"
                            className={classes.input_label}
                          >
                            Number of Distributors*
                          </Typography>
                          <InputBase
                            className={classes.fields_toolbar}
                            placeholder="Enter number of distributors"
                            name="number_of_distributors"
                            value={state.get("number_of_distributors")}
                            type="number"
                            onChange={handleChange}
                            required
                          />
                        </>
                      )}
                      <br />
                      <Grid container>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.disclaimer_msg}
                        >
                          <FormControl component="fieldset">
                            <Typography
                              variant="body1"
                              className={classes.input_label}
                              style={{ textAlign: "start" }}
                            >
                              Do you or your business already bank with Absa?*
                            </Typography>
                            <RadioGroup
                              aria-label="bank_with_absa"
                              name="bank_with_absa"
                              value={state.get("bank_with_absa")}
                              onChange={handleChange}
                              style={{ display: "flex", flexDirection: "row" }}
                              required
                            >
                              <FormControlLabel
                                value="false"
                                control={<Radio color="primary" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="true"
                                control={<Radio color="primary" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Typography>
                      </Grid>

                      <Grid container style={{ marginTop: "15px" }}>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.disclaimer_msg}
                        >
                          <FormControlLabel
                            className={classes.disclaimer_checkbox}
                            style={{ display: "flex", alignItems: "start" }}
                            control={
                              <Checkbox
                                name="consent_to_getting_history"
                                checked={state.get(
                                  "consent_to_getting_history"
                                )}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon color="primary" />}
                                onChange={handleChange}
                                required
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                gutterBottom
                                className={classes.disclaimer_checkbox_msg}
                                style={{ textAlign: "start" }}
                              >
                                I consent to Absa receiving my sales history
                                from my supplier. Absa requires this data
                                strictly to assign you the correct financing
                                amount.*
                              </Typography>
                            }
                          />
                        </Typography>
                      </Grid>

                      <Grid container>
                        <Typography
                          variant="body1"
                          gutterBottom
                          className={classes.disclaimer_msg}
                        >
                          <FormControlLabel
                            className={classes.disclaimer_checkbox}
                            control={
                              <Checkbox
                                name="consent_to_contact_me"
                                checked={state.get("consent_to_contact_me")}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon color="primary" />}
                                onChange={handleChange}
                                required
                              />
                            }
                            label={
                              <Typography
                                variant="body1"
                                gutterBottom
                                className={classes.disclaimer_checkbox_msg}
                              >
                                I consent to Absa contacting me.*
                              </Typography>
                            }
                          />
                        </Typography>
                      </Grid>

                      <br />
                      <Typography
                        variant="body1"
                        className={classes.input_label}
                      >
                        Refer stream/sales code
                      </Typography>
                      <InputBase
                        className={classes.fields_toolbar}
                        placeholder="Refer stream/sales code"
                        name="refer_stream_sales_code"
                        value={state.get("refer_stream_sales_code")}
                        onChange={handleChange}
                      />

                      <br />

                      <Grid container justify="center">
                        <Button
                          variant="contained"
                          align="center"
                          size="large"
                          fullWidth={true}
                          type="submit"
                          className={classes.primary_button}
                          disabled={
                            !(
                              state.get("consent_to_contact_me") === true &&
                              state.get("consent_to_getting_history") ===
                                true &&
                              state.get("bank_with_absa")
                            )
                          }
                        >
                          <b>Register</b>
                        </Button>
                      </Grid>
                    </form>
                    <br />
                    <Grid
                      container
                      justify="center"
                      style={{ marginTop: "7px" }}
                    >
                      <Typography variant="body1">
                        <Link to="/login" className={classes.primary_links}>
                          Login
                        </Link>
                      </Typography>
                    </Grid>

                    <br />
                    <Grid container justify="center">
                      <Typography
                        variant="caption"
                        style={{ textAlign: "center" }}
                      >
                        {footer_message()}
                      </Typography>
                    </Grid>
                    <Grid container justify="center">
                      <Typography variant="caption">
                        Powered by{" "}
                        <a
                          href="https://finplusgroup.com/"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.primary_links}
                        >
                          Finplus Group
                        </a>
                      </Typography>
                    </Grid>
                    <br />
                  </>
                )}
              </Container>

              {snackbarLoader()}
              {progressLoader()}
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(SignUp);
