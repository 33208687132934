import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import { grey } from "@material-ui/core/colors";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
    createMuiTheme,
    ThemeProvider,
    withStyles,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";

const env = runtimeEnv();
const transparent_logo_path =
  env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = env.REACT_APP_PRIMARY_COLOR || "#640032";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  root: {
    flexGrow: 1,
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    color: "white",
  },
  orders_date: {
    float: "right",
    color: grey[700],
    fontTransformation: "none",
  },
  bottom_buttons: {
    fontSize: "18px",
    fontTransformation: "none",
  },
  delivered_btn: {
    backgroundColor: "#3BB719",
    color: grey[50],
    borderRadius: "20px",
    fontSize: "18px",
    textTransform: "unset",
  },
  no_decoration: {
    textDecoration: "none",
  },
});

function OrderDetails(props) {
  const order = useSelector((state) => state.orders.order);
  const retailer_data = useSelector((state) => state.auth.retailer_data);
  const { classes } = props;

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const viewLoanDetails = () => {
    if (order.payment_mode === "FINANCE" || order.payment_mode === "Finance") {
      return (
        <Container>
          <Link to="/loan_details" className={classes.no_decoration}>
            <Button
              variant="outlined"
              className={classes.bottom_buttons}
              fullWidth
            >
              View {retailer_data.is_la_riba === true ? 'facility ' : 'loan '} details
            </Button>
          </Link>
        </Container>
      );
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/order_history" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography className={classes.input} align="center">
                <b>Order No. {order.order_id}</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>
          <br />
          <br />
          <Grid container justify="center">
            <Button
              variant="contained"
              className={classes.delivered_btn}
              disableElevation={true}
            >
              Delivered
            </Button>
          </Grid>
          <List
            component="nav"
            style={{ marginBottom: "60px", borderRadius: "0px" }}
          >
            <ListItem>
              <ListItemText
                primary={
                  <Grid container className={classes.root}>
                    <Grid xs={6} item>
                      <Typography>Created on</Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <Typography className={classes.orders_date}>
                        {order.created_on}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>

            <Divider />

            {order.line_items.map((item, index) => (
              <div>
                <ListItem>
                  <ListItemText
                    primary={
                      <Grid container className={classes.root}>
                        <Grid xs={6} item>
                          <Typography variant="body1">
                            {item.name} (x{item.quantity})
                          </Typography>
                        </Grid>
                        <Grid xs={6} item>
                          <Typography
                            variant="body1"
                            className={classes.orders_date}
                          >
                            {toCurrency(item.price)}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </ListItem>
                <Divider />
              </div>
            ))}

            <ListItem>
              <ListItemText
                primary={
                  <Grid container className={classes.root}>
                    <Grid xs={6} item>
                      <Typography variant="body1">
                        <b>Order total</b>
                      </Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <Typography
                        variant="body1"
                        className={classes.orders_date}
                      >
                        <b>{toCurrency(order.total_with_fees)}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>

            <Divider />

            <ListItem>
              <ListItemText
                primary={
                  <Grid container className={classes.root}>
                    <Grid xs={6} item>
                      <Typography variant="body1">Payment mode</Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <Typography
                        variant="body1"
                        className={classes.orders_date}
                      >
                        {order.payment_mode === 'Credit_card' ? 'Card' : order.payment_mode}
                      </Typography>
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>
          </List>

          {viewLoanDetails()}
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(OrderDetails);
