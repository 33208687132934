import produce from "immer";

const INITIAL_STATE = {
    signed: false,
    retailer_data: {},
};

export default function auth(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case "@auth/LOGIN":
                draft.signed = true;
                draft.retailer_data = action.payload;
                break;
            case "@auth/UPDATE_RETAILER":
                draft.retailer_data = action.payload;
                break;
            case "@auth/SESSION_EXPIRED":
                draft.signed = false;
                break;
            case "@auth/LOGOUT":
                draft.signed = false;
                break;
            default:
        }
    });
}
