import runtimeEnv from "@mars/heroku-js-runtime-env";
import { Paper } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Backdrop from "@material-ui/core/Backdrop";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { amber, grey } from "@material-ui/core/colors";
import {
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CardGiftcardRoundedIcon from "@material-ui/icons/CardGiftcardRounded";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import RemoveIcon from "@material-ui/icons/Remove";
import SearchIcon from "@material-ui/icons/Search";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import MuiAlert from "@material-ui/lab/Alert";
import * as Cookies from "js-cookie";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CommonStyles from "../common/commonStyles";
import HeaderBar from "../common/header_bar";
import { session_expired } from "../store/modules/auth/actions";
import { authorization_headers } from "../utils/api_authorization";
import { appStoreGet, appStoreRemove, appStoreSet } from "../utils/app_storage";
import { decrypt, encrypt } from "../utils/crypto_encryption";

const env = runtimeEnv();
const transparent_logo_path =
  env.REACT_APP_TRANSPARENT_LOGO_PATH || "/assets/images/logo_transparent.png";
const primary_main_color = env.REACT_APP_PRIMARY_COLOR || "#640032";

const appTheme = createMuiTheme({
  palette: {
    primary: {
      main: env.REACT_APP_PRIMARY_COLOR,
    },
    white: {
      main: grey[50],
      body1: {
        fontSize: "18px",
      },
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro"].join(","),
    body1: {
      fontSize: "18px",
    },
  },
});

const styles = (theme) => ({
  ...CommonStyles(theme),
  alert_override: {
    "&": {
      borderLeftWidth: "4px",
      borderTop: "none",
      borderBottom: "none",
      borderRight: "none",
      boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
      width: "100%",
      backgroundColor: "white",
    },
    "& .MuiAlert-message": {
      color: "#5B4C4B",
    },
  },
  customBadge: {
    backgroundColor: "#dc8a07",
    color: "white",
    width: "80px",
    fontWeight: "700",
  },
  outOfStockCustomBadge: {
    backgroundColor: "rgba(0, 0, 0, 0.54)",
    color: "white",
    width: "105px",
    fontWeight: "700",
  },
  toolbar: {
    border: "1px solid",
    borderColor: env.REACT_APP_AGIZA_APP === "true" ? grey[500] : amber[700],
  },
  group_btns: {
    padding: "10px",
  },
  group_btn: {
    borderRadius: "20px",
    margin: 5,
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "unset",
  },
  group_buttons: {
    "MuiFab-sizeSmall": {
      height: "unset",
    },
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: "18px",
    color: "white",
  },
  iconButton: {
    padding: 10,
    color: "white",
  },
  cat_extended_btn: {
    width: "110px",
    height: "30px",
  },
  btn_bottom: {
    border: "0px",
    borderColor: primary_main_color,
  },
  stickToBottom: {
    top: "auto",
    bottom: 0,
    left: "auto",
    right: "auto",
    position: "fixed",
    padding: 0,
  },
  out_of_stock_btn: {
    height: "34px",
    width: "118px",
    minWidth: "118px",
    fontSize: "12px",
    borderRadius: "17px",
  },
  product_image: {
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  list_primary_text: {
    width: "180px",
  },
  list_primary_text_for_out_of_stock: {
    width: "180px",
    color: "rgba(0, 0, 0, 0.54)",
  },
  no_decoration: {
    textDecoration: "none",
  },
  snackbar: {
    textAlign: "center",
    marginTop: "50px",
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="outlined" {...props} />;
}

function Ordering(props) {
  let cached_shopping_cart = [];
  const { classes } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  if (appStoreGet("shopping_cart") !== null) {
    cached_shopping_cart = JSON.parse(appStoreGet("shopping_cart"));
  }

  const [shopping_cart, setShoppingCart] = useState(cached_shopping_cart);
  const [products, setProducts] = useState([]);
  const [product_categories, setProductCategories] = useState([]);
  const [selected_category, setSelectedCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open_snackbar, setOpenSnackbar] = useState(false);
  const [snackbar_message, setSnackbarMessage] = useState(null);
  const [snackbar_severity, setSnackbarSeverity] = useState(null);
  const [search_results, setSearchResults] = useState([]);
  const [search_value, setSearchValue] = useState("");

  appStoreRemove("approved_order_id");

  const closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setSnackbarSeverity(null);
  };

  const fetchProducts = () => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login');
    }

    setLoading(true);

    fetch(env.REACT_APP_SERVER_API_URL + "/products", {
      headers: { ...authorization_headers(), ...{ Authorization: token } },
    })
      .then((res) => res.text())
      .then((res) => {
        let data = JSON.parse(decrypt(res));
        let dub_categories = data.map((item) => item.category);
        let categories = dub_categories
          .filter((e, i) => dub_categories.indexOf(e) === i)
          .sort();

        setProducts(data);
        setSearchResults(data);
        setProductCategories(["Offers"].concat(categories));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // setHasErrors(true)
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    return array.filter((value) => {
      return (
        value.name.toLowerCase().match(new RegExp(searchTerm, "g")) ||
        value.category.toLowerCase().match(new RegExp(searchTerm, "g"))
      );
    });
  };

  const handleOnChange = async (e) => {
    let value = e.target.value;
    setSearchValue(value);
    setSelectedCategory(null);
    if (value.length > 2) {
      let results = await arraySearch(products, value);
      setSearchResults(results);
    } else {
      setSearchResults(products);
    }
  };

  const cancelSearch = () => {
    setSearchResults(products);
    setSearchValue("");
    setSelectedCategory(null);
  };

  const removeFromCart = (product_id) => {
    let updated_cart = shopping_cart.slice();
    let selected_product = updated_cart.filter(function (item) {
      return item.id === product_id;
    })[0];

    if (selected_product !== undefined) {
      let index = updated_cart.findIndex(
        (product) => product.id === product_id
      );

      if (selected_product.quantity === 0 || selected_product.quantity === 1) {
        updated_cart.splice(index, 1);
      } else {
        updated_cart[index].quantity--;
      }

      appStoreSet("shopping_cart", JSON.stringify(updated_cart));
      setShoppingCart(updated_cart);
    }
  };

  const sendOutOfStockAlert = (product_id) => {
    let token = Cookies.get("AbsaAgiza");

    if (!token) {
      dispatch(session_expired());
      history.push('/login');
    }

    setLoading(true);
    let delivery_data = JSON.parse(appStoreGet("delivery_data"));
    let shipping_address = JSON.parse(appStoreGet("shipping_address"));

    let request_data = {
      product_id: product_id,
      distributor_id: delivery_data.distributor_id,
      location: shipping_address.formatted_address,
      coordinates: [shipping_address.latitude, shipping_address.longitude],
    };

    fetch(env.REACT_APP_SERVER_API_URL + "/out_of_stock_alerts", {
      method: "POST",
      headers: { ...authorization_headers(), ...{ Authorization: token } },
      body: encrypt(JSON.stringify(request_data)),
    })
      .then((res) => {
        if (res.status === 201) {
          setSnackbarMessage("Alert submitted");
          setOpenSnackbar(true);
          setSnackbarSeverity("success");
          setLoading(false);
        } else {
          setSnackbarMessage(
            "Error submitting alert. Please try again or contact support."
          );
          setOpenSnackbar(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setSnackbarMessage(
          "Error submitting alert. Please try again or contact support."
        );
        setOpenSnackbar(true);
        setLoading(false);
      });
  };

  const addToCart = (product_id) => {
    let selected_product = products.filter(function (item) {
      return item.id === product_id;
    })[0];

    if (selected_product !== undefined) {
      let existing_product = shopping_cart.filter(function (item) {
        return item.id === product_id;
      })[0];

      let updated_cart = shopping_cart.slice();

      if (existing_product !== undefined) {
        let index = updated_cart.findIndex(
          (product) => product.id === product_id
        );

        if (index !== -1) {
          updated_cart[index].quantity++;
        }
      } else {
        selected_product.quantity = 1;
        updated_cart.push(selected_product);
      }

      appStoreSet("shopping_cart", JSON.stringify(updated_cart));
      setShoppingCart(updated_cart);
    }
  };

  const cartQuantity = () => {
    let sum = 0;
    shopping_cart.forEach(function (item) {
      sum += item.quantity;
    });

    return sum;
  };

  const cartTotal = () => {
    let total = 0;
    shopping_cart.forEach(function (item) {
      total += item.quantity * parseInt(item.price);
    });

    return total;
  };

  const cartProductQualtity = (product_id) => {
    let selected_product = shopping_cart.filter(function (item) {
      return item.id === product_id;
    })[0];

    if (selected_product !== undefined) {
      return selected_product.quantity;
    }
    return 0;
  };

  const showProductsFromACategory = async (category_name) => {
    let results = await arraySearch(products, category_name);
    setSearchResults(results);
    setSearchValue("");
  };

  const showProductsOnOffers = async () => {
    let results = await arraySearch(products, "promotion");
    setSearchResults(results);
    setSearchValue("");
  };

  const setSearch = (category_name, index) => {
    setSelectedCategory(index);
    if (category_name === "Offers") {
      showProductsOnOffers();
    } else {
      showProductsFromACategory(category_name);
    }
  };

  const toCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "KSH",
    });

    return formatter.format(number);
  };

  const addToCartOrRemove = (product, classes) => {
    if (product.stocked) {
      return (
        <ButtonGroup color="primary" size="small">
          <Fab
            onClick={() => removeFromCart(product.id)}
            disabled={!product.stocked}
            style={{
              borderRadius: "17px 0 0 17px",
              backgroundColor: primary_main_color,
              fontSize: "18px",
              height: "unset",
            }}
          >
            <RemoveIcon />
          </Fab>
          <Button
            variant="contained"
            style={{
              backgroundColor: primary_main_color,
              fontSize: "18px",
              height: "unset",
            }}
            disabled={!product.stocked}
          >
            {cartProductQualtity(product.id)}
          </Button>
          <Fab
            onClick={() => addToCart(product.id)}
            disabled={!product.stocked}
            style={{
              borderRadius: "0 17px 17px 0",
              backgroundColor: primary_main_color,
              fontSize: "18px",
              height: "unset",
            }}
          >
            <AddIcon />
          </Fab>
        </ButtonGroup>
      );
    } else {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.out_of_stock_btn}
          onClick={() => sendOutOfStockAlert(product.id)}
        >
          Alert shop{" "}
          <SentimentVeryDissatisfiedIcon style={{ fontSize: "18px" }} />
        </Button>
      );
    }
  };

  const viewShoppingCart = () => {
    history.push("/shopping_cart");
  };

  const productImage = (url, classes) => {
    if (url) {
      return (
        <Avatar src={url} variant="square" className={classes.product_image} />
      );
    } else {
      return (
        <Avatar variant="square" className={classes.product_image}>
          <LocalBarIcon />
        </Avatar>
      );
    }
  };

  const onOffer = (product, classes) => {
    if (product.stocked && product.stocked === false) {
      return (
        <span>
          {" "}
          ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‏‏‎ ‏‏‎ ‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎{" "}
          <Badge
            badgeContent={"OUT OF STOCK"}
            classes={{ badge: classes.outOfStockCustomBadge }}
          />
          <br />
          <span>{toCurrency(product.price)}</span>
        </span>
      );
    }
    if (product.old_price && product.old_price !== null) {
      return (
        <span>
          {" "}
          ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎ ‎ ‎‏‏‎ ‎‏‏‎ ‎‏‏‎{" "}
          <Badge
            badgeContent={"ON OFFER"}
            classes={{ badge: classes.customBadge }}
          />
          <br />
          <span style={{ textDecoration: "line-through" }}>
            {toCurrency(product.old_price)}
          </span>
          ‎‏‏‎ ‎‏‏‎ ‎‏‏‎
          <span style={{ color: "#dc8a07" }}>
            <b>{toCurrency(product.price)}</b>
          </span>
        </span>
      );
    } else {
      return toCurrency(product.price);
    }
  };

  const verticalLeftBorder = (product) => {
    if (product.old_price && product.old_price !== null) {
      return {
        borderLeftColor: "#dc8a07",
        borderLeftStyle: "solid",
        borderLeftWidth: "10px",
      };
    }
  };

  const categoryColor = (index) => {
    if (index === 0) {
      if (env.REACT_APP_AGIZA_APP === "true") {
        return {
          bgcolor: "#ffffff",
          color: primary_main_color,
          border: "2px solid " + primary_main_color,
        };
      } else {
        return { bgcolor: "#dc8a07", color: "#ffffff", border: null };
      }
    } else {
      return {
        bgcolor: "#ffffff",
        color: primary_main_color,
        border: "2px solid " + primary_main_color,
      };
    }
  };

  return (
    <Fragment>
      <CssBaseline />
      <ThemeProvider theme={appTheme}>
        <Paper
          className={classes.container}
          style={{
            height: "100vh",
          }}
        >
          <HeaderBar>
            <Toolbar>
              <Link to="/" className={classes.no_decoration}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="directions"
                  color="primary"
                  disableRipple
                >
                  <ArrowBackIosIcon />
                </IconButton>
              </Link>
              <Typography className={classes.input} align="center">
                <b>Products Catalog</b>
              </Typography>
              <Link to="/" className={classes.no_decoration}>
                <Avatar
                  src={env.REACT_APP_TRANSPARENT_LOGO}
                  variant="square"
                  className={classes.square}
                />
              </Link>
            </Toolbar>
          </HeaderBar>

          <AppBar
            id="cat_list"
            position="sticky"
            color="white"
            style={{ boxShadow: "none" }}
          >
            <Toolbar className={classes.toolbar}>
              <IconButton style={{ padding: 10 }} aria-label="directions">
                <SearchIcon />
              </IconButton>
              <InputBase
                className={classes.input}
                placeholder="Search product"
                inputProps={{ "aria-label": "search product" }}
                value={search_value}
                onChange={handleOnChange}
              />
              <Button
                style={{ color: grey[600], fontSize: "18px" }}
                aria-label="directions"
                onClick={cancelSearch}
              >
                Cancel
              </Button>
            </Toolbar>
          </AppBar>
          <Container
            style={{
              padding: "0",
              maxHeight: `calc(100vh - 128px)`,
              overflow: "auto",
            }}
          >
            <Container
              maxWidth="sm"
              align="center"
              className={classes.group_btns}
            >
              {product_categories.map((category, index) => (
                <Button
                  color="primary"
                  variant="contained"
                  size="small"
                  className={classes.group_btn}
                  style={{
                    backgroundColor:
                      selected_category === index
                        ? primary_main_color
                        : categoryColor(index).bgcolor,
                    color:
                      selected_category === index
                        ? "#ffffff"
                        : categoryColor(index).color,
                    border:
                      selected_category === index
                        ? null
                        : categoryColor(index).border,
                  }}
                  onClick={() => setSearch(category, index)}
                  onMouseDown={() => setSearch(category, index)}
                >
                  {category === "Offers" ? (
                    <CardGiftcardRoundedIcon></CardGiftcardRoundedIcon>
                  ) : null}
                  {category}
                </Button>
              ))}
            </Container>
            <List
              style={{
                marginBottom: "60px",
                borderRadius: "0px",
                backgroundColor: "white",
              }}
            >
              {search_results.map((product, index) => (
                <ListItem key={index} style={verticalLeftBorder(product)}>
                  {productImage(product.image_url, classes)}
                  <ListItemText
                    classes={{
                      primary:
                        product.stocked && product.stocked
                          ? classes.list_primary_text
                          : classes.list_primary_text_for_out_of_stock,
                    }}
                    primary={<Typography>{product.name}</Typography>}
                    secondary={
                      <div>
                        <Typography variant="body1" gutterBottom>
                          <b>{onOffer(product, classes)}</b>
                        </Typography>
                      </div>
                    }
                    style={{ paddingRight: "18px" }}
                  >
                    {toCurrency(product.price)}
                  </ListItemText>

                  {addToCartOrRemove(product, classes)}
                </ListItem>
              ))}
            </List>
          </Container>
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              className={classes.snackbar}
              open={open_snackbar}
            >
              <Alert
                onClose={() => closeSnackbar()}
                className={classes.alert_override}
                severity={snackbar_severity || "warning"}
              >
                {snackbar_message}
              </Alert>
            </Snackbar>
          </div>
          <AppBar
            className={`${classes.stickToBottom} ${classes.contained_width}`}
            color="primary"
          >
            <Toolbar style={{ padding: 0 }}>
              <ButtonGroup
                style={{ height: "60px", padding: "0" }}
                size="small"
                color="primary"
                variant="contained"
                fullWidth={true}
              >
                <Button
                  style={{
                    borderRight: "1px white solid",
                    fontSize: "18px",
                    width: "33%",
                  }}
                >
                  <b>{cartQuantity()} ITEMS</b>
                </Button>
                <Button
                  style={{ border: "0px", fontSize: "18px", width: "33%" }}
                >
                  <b>{toCurrency(cartTotal())}</b>
                </Button>
                <Button
                  style={{
                    borderLeft: "1px white solid",
                    fontSize: "18px",
                    margin: "2px",
                    width: "34%",
                  }}
                  onClick={() => viewShoppingCart()}
                >
                  <b>VIEW BASKET</b>
                </Button>
              </ButtonGroup>
            </Toolbar>
          </AppBar>
        </Paper>
      </ThemeProvider>
    </Fragment>
  );
}

export default withStyles(styles)(Ordering);
